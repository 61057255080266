/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from "react";
import TableView from "../components/tableView";
import { useAuthStore } from "../store/authStore";
import { Navigate, useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import FastAPIClient from "../utils/client";
import Plot from 'react-plotly.js';
import { ButtonGroup, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToggle, Alert } from "reactstrap";
import { convertTimestamp } from "../utils/utils";
import { Nav, Navbar, NavDropdown, Offcanvas, Container, Spinner, ProgressBar } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { NavLink } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import PlotObj from "../components/plotObj";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { Toast } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import BTButton from 'react-bootstrap/Button'
import Select from "react-select";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import _ from "lodash";
import TokenService from "../utils/token";
import { varMapper } from "../utils/const";

const client = new FastAPIClient();

const logo = require("../photos/Resonant-Link-Logo_White.png");

const ResponsiveGridLayout = WidthProvider(Responsive);

function PlottingPageV2() {
  // look up context to refrain from re-querying db. 
  let params = useParams();
  let navigate = useNavigate()
  const navLinkStyle = {
    fontWeight: "bold",
    fontSize: "20px",
  };
  const metadataFK = params.metadataFK;
  //const [isLoading, setLoading] = useState(true);
  const [dbData, setData] = useState([{}]);
  const [tableView, setTableView] = useState(false);
  const [currentActiveX, setCurrentActiveX] = useState("");
  const [currentActiveY, setCurrentActiveY] = useState([]);
  const [allTemplates, setallTemplates] = useState([]);
  const [templateToLoad, setTemplateToLoad] = useState({});
  const [searchParams, _fncs] = useSearchParams();
  const [templateSelectShow, setTemplateSelectShow] = useState({});
  const [currentTemplateObj, setCurrentTemplateObj] = useState({});
  const [templateOptions, setTemplateOptions] = useState([]);
  const [xArr, setxArr] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [plotDict, setPlotDict] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [selectedOptionX, setSelectedOptionX] = useState([]);
  const [selectedOptionY, setSelectedOptionY] = useState([]);
  const [counter, setCounter] = useState(0);
  const [modalWarningNameShow, setModalWarningNameShow] = useState(false);
  const [modalWarningOwnersShow, setModalWarningOwnersShow] = useState(false);
  const [modalLoadShow, setModalLoadShow] = useState(false);
  const [modalNameShow, setModalNameShow] = useState(false);
  const [showSaveOptions, setShowSaveOptions] = useState(false);
  const [templateId, setTemplateId] = useState(searchParams.get("templateId"));
  const [telDataFetchComplete, setTelDataFetchComplete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const toggleTableView = () => setTableView(!tableView);
  const [isLoading, setIsLoading] = useState(true);
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const [progressBarLabel, setProgressBarLabel] = useState("Fetching Data");
  const [csvConfig, setCsvConfig] = useState({});
  const [schemaVersion, setSchemaVersion] = useState(1);
  const location = useLocation();
  const [layouts, setLayouts] = useState({
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: []
  })
  const [accessToken, setAccessToken] = useState(TokenService.getToken());
  const [userEmail, setUserEmail] = useState("");
  function prepDataForCsv(dbData) {
    const returnObjArray = []
    for (const entry of dbData) {
      const objHolder = {};
      for (const key of Object.keys(entry)) {
        if (varMapper[key] === undefined) {
          continue
        }
        objHolder[varMapper[key]] = entry[key];
      }
      returnObjArray.push(objHolder);
    }
    const csvExporter = generateCsv(csvConfig)(returnObjArray);
    download(csvConfig)(csvExporter);
  }

  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
  }
  const { userProfile, addUser, removeUser } = useAuthStore();

  const handleTokenChange = () => {
    setAccessToken(TokenService.getToken());
  }
  TokenService.addTokenChangeListener(handleTokenChange);

  const forceUpdate = useForceUpdate();

  function handleMarkerChange(markerType, dataIndex) {
    plotData[dataIndex].mode = markerType;
    setPlotData(plotData)
    forceUpdate()
  }

  function clearStates() {
    setCurrentActiveX("");
    setCurrentActiveY([]);
    setxArr([]);
    setPlotData([]);
    setSelectedOptionX([]);
    setSelectedOptionY([]);
    setModalLoadShow(false);
  }

  const handleChangeTemplateLoad = (selectedOption) => {
    setTemplateToLoad(selectedOption.value);
    setTemplateSelectShow(selectedOption);
  }

  const handleChangeX = (selectedOption) => {
    setSelectedOptionX(selectedOption);
    const xValue = selectedOption.value;
    // Start forming the plot data
    var x_axis = dbData.map((item) => item[xValue]);
    setxArr(x_axis);
    setCurrentActiveX(xValue);
  };

  const handleChangeY = (selectedOption) => {

    setSelectedOptionY(selectedOption);
    // selectionOption is an array, 
    // We can iterate through this array
    // form // edit the plot dictionary
    var localPlotDict = [];
    var allYNames = [];
    for (const yObj of selectedOption) {
      let plotObj = {};
      let yLabel = yObj.label;
      let yValue = yObj.value;
      plotObj = {
        x: xArr,
        y: dbData.map((item) => item[yValue]),
        type: "scatter",
        mode: "lines",
        name: yLabel,
        ...(localPlotDict.length === 1 && { yaxis: 'y2' })
      }
      localPlotDict.push(plotObj);
      allYNames.push(yValue)
    }
    setPlotData(localPlotDict);

    setCurrentActiveY(allYNames);
  };

  useEffect(() => {
    client
      .getMetadataByPk(metadataFK, accessToken)
      .then((data) => {
        setSchemaVersion(data[0].schemaVersion);
      })
  }, [metadataFK]);

  useEffect(() => {
    client
      .getTelemetryDataByMetadataFk(metadataFK, accessToken)
      .then((data) => {
        if (data === 403) {
          removeUser();
          navigate("/login/tkc");
        }
        if (data === 404) {
          setData({});
          return
        }
        // convert timestamps real quick
        for (let i in data) {
          data[i]["timestamp"] = convertTimestamp(data[i]["timestamp"])
        }
        setData(data);
        setTelDataFetchComplete(true);
        let dateString = new Date(Number(metadataFK.split('-')[0]));
        let fileNameHolder = `${dateString.getFullYear()}_${dateString.getMonth() + 1}_${dateString.getDate()}_${dateString.getHours()}_${dateString.getMinutes()}_${dateString.getSeconds()}`
        if (data[0].datalogBsmSN) {
          fileNameHolder = fileNameHolder + `_TBS_${data[0].datalogBsmSN}`;
        }
        if (data[0].datalogOvmSN) {
          fileNameHolder = fileNameHolder + `_OVR_${data[0].datalogOvmSN}`;
        }
        const csvConfigHolder = mkConfig({ useKeysAsHeaders: true, filename: fileNameHolder });
        setCsvConfig(csvConfigHolder);
        setProgressBarPercent(40);
        setProgressBarLabel("Data Fetch Complete");
      })
  }
    , [metadataFK]);

  useEffect(() => {
    if (accessToken && telDataFetchComplete) {
      client.getAllTemplates(accessToken)
        .then((data) => {
          setallTemplates(data)
          setProgressBarPercent(60);
          setProgressBarLabel("Formatting Data");
          var templateOptions = [];
          for (const id of data) {
            if (`${id.templateId}` === `${templateId}`) {
              setCurrentTemplateObj(id)
            }
            let emailSplit = id.email;
            emailSplit = emailSplit.split("@")
            emailSplit = emailSplit[0]
            templateOptions.push({
              "value": id,
              "label": `Plot Name:${id.plotName} -- Owner: ${emailSplit} -- id:${id.templateId}`
            })
          }
          setTemplateOptions(templateOptions);
          const localToken = TokenService.getToken();
          if (templateId || templateId === 0) {
            const getTemplates = client.getPlottingTemplate(templateId, localToken)
              .then((data) => {
                if (Array.isArray(data)) {
                  // if we reached here, we had some issues with token stuff 
                  // should redirect to log in
                  removeUser();
                  navigate("/login/tkc")
                } else if (Object.keys(data).includes("status")) {
                  const respStatus = data["status"];
                  if (respStatus === 403) {
                    // This means we tried to access a template that
                    // is not part of the org 
                    // should redirect to 0
                    navigate(`${location.pathname}?templateId=0`);
                    // should implement some kind of alert that tells user 
                    // what happened
                  } else {
                    navigate(`${location.pathname}?templateId=0`);
                  }
                  setTemplateId(0);
                  setShowToast(true);
                  return
                }

                if (Number.isInteger(data)) {
                  navigate(`${location.pathname}?templateId=${templateToLoad.templateId}`)
                  return
                }
                // Replace the placeholder names in the plotData with actul data now 
                setProgressBarPercent(80);
                setProgressBarLabel("Rendering ...");
                var retrievedPlotDict = data.plotDict;
                let plotDictKeys = Object.keys(retrievedPlotDict);
                var plotDataHolder = []
                for (var key of plotDictKeys) {
                  let retrievedPlotData = retrievedPlotDict[key].plotData;
                  for (var idx in retrievedPlotData) {
                    let plotObj = {}
                    plotObj.mode = retrievedPlotData[idx].mode
                    plotObj.name = retrievedPlotData[idx].name
                    plotObj.type = retrievedPlotData[idx].type

                    const currXName = retrievedPlotData[idx].x;
                    const currYName = retrievedPlotData[idx].y;
                    plotObj.x = dbData.map((item) => item[currXName]);
                    plotObj.y = dbData.map((item) => item[currYName]);
                    plotDataHolder.push(plotObj);
                    retrievedPlotDict[key].plotData[idx].x = plotObj.x
                    retrievedPlotDict[key].plotData[idx].y = plotObj.y
                  }
                }
                setPlotDict(data.plotDict);
                setPlotData(plotDataHolder);
                setLayouts(data.layoutDict);
                setIsLoading(false);
              });
          }
        });
    };
  }, [metadataFK, templateId, telDataFetchComplete]);

  useEffect(() => {
    if (plotData.length > 0) {
      for (const plotObj of plotData) {
        plotObj.x = dbData.map((item) => item[currentActiveX])
      }
    }
  }, [dbData, currentActiveX, plotData]
  )

  if (dbData) {
    const objectKeys = Object.keys(dbData[0]).sort();
    // we have to reformat the obj so that react-select can use it 
    var objOptions = [];
    for (const key of objectKeys) {
      if (!varMapper[key]) {
        continue
      }

      objOptions.push({
        "value": key,
        "label": varMapper[key] === "Date" ? "timestamp" : varMapper[key],
      })
    }
  }
  const addItem = () => {
    var new_layout = {
      i: null,
      x: null,
      y: 0,
      w: 4,
      h: 2,
    }
    let new_count = counter;
    if (layouts["lg"].length === 0) {
      new_count = counter + 1;
      new_layout.i = "n" + (counter + 1);
      new_layout.x = 0;
    } else {
      let existing_arr = layouts["lg"];
      const prev_id = existing_arr[existing_arr.length - 1]["i"]
      const new_count = Number(prev_id.split("n")[1]) + 1
      new_layout.i = "n" + (new_count);
      new_layout.x = (existing_arr.length * 2) % 12

    }
    for (const key in layouts) {
      layouts[key].push(new_layout)
    }

    let existingPlotDict = plotDict;
    existingPlotDict[new_layout.i] = {};

    existingPlotDict[new_layout.i].xName = currentActiveX;
    existingPlotDict[new_layout.i].yName = currentActiveY;
    existingPlotDict[new_layout.i].plotData = plotData;
    setPlotDict(existingPlotDict);
    setLayouts(layouts);
    setCounter(new_count)
    forceUpdate()
  }

  const renderModalEdit = (yName) => {
    const matchingDataIndex = currentActiveY.findIndex((element) => element === yName);
    return (
      <div>
        {`y${matchingDataIndex}`}
        <ButtonGroup size='sm' style={{ marginLeft: 100 }}>
          <Button onClick={() => handleMarkerChange("markers", matchingDataIndex)}>Scatter Only</Button>
          <Button onClick={() => handleMarkerChange("lines", matchingDataIndex)}>Line Only</Button>
          <Button onClick={() => handleMarkerChange("lines+markers", matchingDataIndex)}>Line + Scatter</Button>
        </ButtonGroup>
      </div>
    )

  }

  function MyVerticallyCenteredModal(props) {

    const yTitle = [];
    for (const yT of currentActiveY) {
      yTitle.push(varMapper[yT]);
    }
    let xTitle = varMapper[currentActiveX] ? varMapper[currentActiveX] : ""
    xTitle = xTitle === "Date" ? "timestamp" : xTitle;


    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ color: 'black', }}
        animation={false}

      >
        <Modal.Header closeButton style={{ backgroundColor: "grey" }} >
          <Modal.Title id="contained-modal-title-vcenter">
            Adding Plot
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "grey" }}>
          <Plot
            data={plotData}
            layout={{
              title: `${xTitle} vs ${yTitle}`,
              xaxis: { title: `${xTitle}` },

              ...(plotData.length <= 1 && { yaxis: { title: `${yTitle}` } }),
              ...(plotData.length > 1 && {
                yaxis: { title: `${yTitle[0]}` },
                yaxis2: {
                  title: `${yTitle[1]}`,
                  overlaying: 'y',
                  side: 'right'
                },
              })
            }}
            className='modal_plot'

          />
          <div style={{ flex: 1 }}>
            <div className='gap-6' style={{ flex: 1 }}>
              <h3 style={{ textAlign: "center" }}>X - Axis</h3>
              <Select className='gap-4' options={objOptions} onChange={handleChangeX} value={selectedOptionX}></Select>
            </div>
            <div style={{ flex: 1, marginTop: 50, padding: "3px 0" }}>
              <h3 style={{ textAlign: "center" }}>Y - Axis</h3>
              <Select isMulti options={objOptions} onChange={handleChangeY} value={selectedOptionY}></Select>
            </div>

            <br />
            {currentActiveY.map((yName) => renderModalEdit(yName))}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "grey" }}>
          <Button color="secondary" onClick={() => setModalShow(false)}>Close</Button>
          <Button color="info" onClick={() => {
            addItem();
            setModalShow(false);
          }}>add item</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    if (!userProfile.tk) {
      removeUser();
      navigate("/");
    } else if (userProfile.tk === "invalid") {
      removeUser();
      navigate("/");
    } else if (!userProfile.verified) {
      navigate("/verify");
    }
    else {
      const decoded = jwt_decode(userProfile.tk);
      if (Date.now() >= decoded.exp * 1000) {
        removeUser();
        navigate("/login/exp");
      }
      setUserEmail(decoded["sub"]);
    }
  }, [])

  function handleLayoutChange(localLayout, currentLayouts) {
    setLayouts(currentLayouts)
  }

  async function handleSaveTemplate(saveAs) {

    // Replace num arrays in plotDict so that we can store it in db cleaner


    if (layouts.lg.length === 0) {
      console.log("Nothing to do!")
      return
    }
    if (currentTemplateObj.email && currentTemplateObj.email !== userEmail && !saveAs) {
      setModalWarningOwnersShow(true)
      return
    }

    let plotDictToUpload = {};
    const plotDictKeys = Object.keys(plotDict);
    for (const key of plotDictKeys) {
      let localXName = plotDict[key].xName;
      let localYName = plotDict[key].yName;
      plotDictToUpload[key] = plotDict[key];
      for (const idx in plotDictToUpload[key].plotData) {
        let plotObj = plotDictToUpload[key].plotData[idx]
        plotObj.x = localXName;
        plotObj.y = localYName[idx];
        plotDictToUpload[key].plotData[idx] = plotObj
      }
    }

    var currentTemplateName = null;
    if (currentTemplateObj.plotName) {
      currentTemplateName = currentTemplateObj.plotName;
    } else {
      setModalWarningNameShow(true);
      return
    }
    const resp = await client.postPlottingTemplate(plotDictToUpload,
      layouts,
      saveAs ? null : templateId,
      currentTemplateName,
      userEmail,
      accessToken)

    if (typeof resp === "number") {
      setTemplateId(resp);
      navigate(`${location.pathname}?templateId=${resp}`)
    } else {
    }
  }

  const onEditNameSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    if (formDataObj.plotName.length > 0) {
      currentTemplateObj.plotName = formDataObj.plotName
      setCurrentTemplateObj(currentTemplateObj)

    }
    setModalNameShow(false)
  }

  const handleNavigateTemplate = () => {
    if (!templateToLoad) {
      setModalLoadShow(false)
      return
    }

    clearStates();
    setModalLoadShow(false);
    setTemplateId(templateToLoad.templateId);
    navigate(`${location.pathname}?templateId=${templateToLoad.templateId}`, { replace: true })
  }

  function NameWarningModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="loadShow"
        centered
        style={{ color: 'black', }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="loadShow">
            WARNING!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          PLEASE SET PLOT NAME FIRST BEFORE SAVING TEMPLATE!
        </Modal.Body>
        <ButtonGroup>
          <Button color='primary' onClick={() => { setModalWarningNameShow(false); setModalNameShow(true) }}>OK. GOT IT</Button>
        </ButtonGroup>
      </Modal>
    );
  }

  function OwnersWarningModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="loadShow"
        centered
        style={{ color: 'black', }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="loadShow">
            WARNING!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ONLY OWNER OF THIS TEMPLATE CAN OVERWITE!
          Do you want to save as another template?
        </Modal.Body>
        <ButtonGroup>
          <Button onClick={props.onHide}> Nope </Button>
          <Button color='primary' onClick={() => { setModalWarningOwnersShow(false); handleSaveTemplate(true) }}> Yes </Button>
        </ButtonGroup>
      </Modal>
    );
  }

  function EditNameModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="loadShow"
        centered
        style={{ color: 'black', }}
        animation={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="loadShow">
            Edit Plot Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onEditNameSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Plot Name</Form.Label>
              <Form.Control name="plotName" placeholder="Enter Plot Name" />
              <BTButton type="submit" className="float-right mt-2">
                Save
              </BTButton>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );

  }

  function LoadPlotModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="loadShow"
        centered
        style={{ color: 'black', }}
        animation={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="loadShow">
            Select Template to Navigate To
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select onChange={handleChangeTemplateLoad} value={templateSelectShow} options={templateOptions}></Select>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button onClick={props.onHide}>Close</Button>
            <Button color="primary" onClick={() => handleNavigateTemplate()}>Navigate</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      <Navbar className="bg-resBlack m-auto" data-bs-theme="dark" expand={false}>
        <Navbar.Brand href="/">
          <img
            alt="Resonant-Link-Logo_White"
            src={logo}
            style={{
              height: 60,
              width: 210,
              marginLeft: '20px'
            }}
          />
        </Navbar.Brand>
        <Nav fluid style={{ flex: 1 }}>
          <Navbar.Text style={{ marginLeft: "100px", color: "#FAFBFF", fontSize: "30px", fontWeight: "bold", width: "150%", textAlign: "center" }}>
            Plotting {currentTemplateObj.plotName}
          </Navbar.Text>
        </Nav>

        {(userProfile && userProfile.tk) ? (
          (userProfile.tk === "invalid") ? (
            <Navigate to="/login/tkc" replace={true} />
          ) : (
            <div className="flex-grow-1 justify-content-end flex-row flex gap-6 p-2 justify-center items-center">
              <NavDropdown className={"text-2xl"} style={{ color: "#C7DE30" }}
                title={"Plotting Tools"}
                onMouseLeave={() => (setShowSaveOptions(false))}>
                {isLoading ? (
                  <NavDropdown.Item>
                    LOADING ...
                  </NavDropdown.Item>) :
                  (<>
                    {tableView ? (<></>) : (
                      <>
                        <NavDropdown.Item onClick={() => { clearStates(); setModalShow(true) }}>
                          Add Plot
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setModalLoadShow(true)}>
                          Load Template
                        </NavDropdown.Item>
                        <NavDropdown.Item onMouseLeave={() => (setShowSaveOptions(false))}>
                          <NavDropdown show={showSaveOptions}
                            drop={"start"} title={"Save"}
                            onMouseEnter={() => { setShowSaveOptions(true) }}
                          >
                            <NavDropdown.Item onClick={() => handleSaveTemplate(false)}>
                              Save Template
                            </NavDropdown.Item>
                            <NavDropdown.Item divider />
                            <NavDropdown.Item onClick={() => handleSaveTemplate(true)}>
                              Save as ...
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => setModalNameShow(true)}>
                              Edit Plot Name
                            </NavDropdown.Item>
                          </NavDropdown>
                        </NavDropdown.Item>
                      </>)}
                    <NavDropdown.Item style={{ border: "rounded" }} onClick={() => toggleTableView()}>
                      {tableView ? <>View Plotting Data</> : <>View Data as Table</>}
                    </NavDropdown.Item>
                    <NavDropdown.Item style={{ backgroundColor: "yellow", border: "rounded" }} onClick={() => prepDataForCsv(dbData)}>
                      Export Data as CSV
                    </NavDropdown.Item>
                  </>)}
              </NavDropdown>
              <Nav className="">
                <div>
                  {[false].map((expand) => (
                    <Container fluid>
                      <Navbar.Toggle className="bg-white" aria-controls={`offcanvasNavbar-expand-${expand}`} />
                      <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        className="bg-black text-white"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            Menu
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Nav className="me-auto" navbar>
                            {/* <NavbarText> Welcome {userProfile.name}!</NavbarText> */}
                            <NavDropdown.Item>
                              <Nav.Link as={NavLink} to="/data/overview" style={navLinkStyle}>
                                All Data
                              </Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Nav.Link as={NavLink} to="/data-dashboard" style={navLinkStyle}>
                                Fleet Performance
                              </Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Nav.Link as={NavLink} to="/contact" style={navLinkStyle}>
                                Contact Us
                              </Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Nav.Link
                                href="/"
                                style={navLinkStyle}
                                onClick={() => {
                                  navigate("/");
                                  googleLogout();
                                  removeUser();
                                }}
                              >
                                Logout
                              </Nav.Link>
                            </NavDropdown.Item>
                          </Nav>
                        </Offcanvas.Body>
                      </Navbar.Offcanvas>
                    </Container>
                  ))}
                </div>
              </Nav>
            </div>
          )) : (
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <div>
              <Button variant='res'
                size='lg'
                style={{ fontWeight: "bold" }}
                onClick={() => navigate("/login")}>
                Log In
              </Button>
            </div>
          </Nav>
        )}
      </Navbar>

      <div style={{ float: 'right', flex: 1, display: 'flex', flexDirection: 'row', textAlign: 'center', }}>
        <NameWarningModal show={modalWarningNameShow}
          onHide={() => setModalWarningNameShow(false)} />

        <OwnersWarningModal show={modalWarningOwnersShow}
          onHide={() => setModalWarningOwnersShow(false)} />

        <LoadPlotModal show={modalLoadShow}
          onHide={() => setModalLoadShow(false)} />
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <EditNameModal show={modalNameShow}
          onHide={() => setModalNameShow(false)} />
      </div>

      <Toast style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }} show={showToast} onClose={toggleShowToast} bg='danger' animation delay={3000} autohide>
        <Toast.Header style={{ textAlign: 'center' }} className="rounded text-center w-full">
          <strong className="text-center me-auto"></strong>
        </Toast.Header>
        <Toast.Body>
          <b> You do not have permissions to view this template. Redirected you to
            template id 0</b>
        </Toast.Body>
      </Toast>
      {isLoading ? (
        <div style={{ width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: "column", gap: "10px" }}>
          <Spinner animation="border" role="status" style={{ width: "20rem", height: "20rem", marginTop: '5px' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <b>{progressBarLabel}</b>
          <ProgressBar striped now={progressBarPercent} label={progressBarPercent + "%"} style={{ width: "80%" }} />
        </div>) : (
        (tableView) ? (<TableView dbData={dbData} schemaVersion={schemaVersion} />) :
          (<ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            isDraggable
            onLayoutChange={(e, dummy) => handleLayoutChange(e, dummy)}
            isRearrangeable
            isResizable
            draggableHandle=".plot_header"
            breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
            {_.map(layouts.lg, el =>
              <div key={el.i} data-grid={el}>
                <PlotObj
                  xName={plotDict[el.i].xName}
                  yName={plotDict[el.i].yName}
                  plotData={plotDict[el.i].plotData}
                  l_id={el.i}
                  layouts={layouts}
                  setLayouts={setLayouts}
                  forceUpdate={forceUpdate} />
              </div>)}

          </ResponsiveGridLayout>))

      }
    </div>

  )


}



export default PlottingPageV2;