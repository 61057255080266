import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar";
import { Button, Card, Collapse, ListGroup, Modal, Form, Dropdown, Toast, Alert } from "react-bootstrap";
import { CheckBoxWithTexSingular, CheckBoxWithText } from "../components/checkboxWithText";
import { ChargeType } from "../utils/const";
import { Circle, CheckCircle, CircleX } from "lucide-react";
import { WeekdayAndTimePicker } from "../components/weekdayAndTimePicker";
import FastAPIClient from "../utils/client";
import TokenService from "../utils/token";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/authStore";
import { Weekdays } from "../utils/const";
import { CollapsableListGroupItem } from "../components/collapsableListItem";

const client = new FastAPIClient();

function AddRuleModal({ openModal,
    setOpenModal,
    selectedBsm,
    setSelectedBsm,
    selectedReceivers,
    setSelectedReceivers,
    selectedChargeType,
    setSelectedChargeType,
    allBsms,
    allOvrs,
    accessToken,
    setShowSucess,
    setRefreshRules,
    selectedRule,
    setSelectedRule,
    setSuccessString }) {

    const [activeSelection, setActiveSelection] = useState(99);
    const [cadence, setCadence] = useState(undefined);
    const [fromDaySelected, setFromDaySelected] = useState("Sunday");
    const [fromHourSelected, setFromHourSelected] = useState(0);
    const [fromMinuteSelected, setFromMinuteSelected] = useState(0);
    const [toDaySelected, setToDaySelected] = useState("Sunday");
    const [toHourSelected, setToHourSelected] = useState(0);
    const [toMinuteSelected, setToMinuteSelected] = useState(0);
    const [selectAllBsmFlag, setSelectAllBsmFlag] = useState(false);
    const [selectAllOvrFlag, setSelectAllOvrFlag] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState("");
    const toggleShowToast = () => setShowToast(!showToast);

    const handleSelectAllBsms = () => {

        if (!selectAllBsmFlag) {
            const bsms = [];
            allBsms.map((bsm) => { bsms.push(String(bsm)) })
            setSelectedBsm([...bsms]);
        } else {
            setSelectedBsm([]);
        }
        setSelectAllBsmFlag(!selectAllBsmFlag);
    }

    const handleSelectAllReceivers = () => {

        if (!selectAllOvrFlag) {
            const ovrs = [];
            allOvrs.map((ovr) => { ovrs.push(String(ovr)) })
            setSelectedReceivers([...ovrs]);
        } else {
            setSelectedReceivers([]);
        }
        setSelectAllOvrFlag(!selectAllOvrFlag);
    }

    const handleModalSubmit = async () => {

        // Refer to ScheduleInput in schema.py
        const scheduleInput = {};
        scheduleInput['receivers'] = selectedReceivers;
        scheduleInput['chargers'] = selectedBsm;
        scheduleInput['charge_type'] = selectedChargeType[0]

        // active section == 2 is default 
        if (activeSelection === 2) {
        } else if (activeSelection === 0) {
            // Cadence handler 
            if (cadence === undefined) {
                setToastText("Please select the number of charges");
                setShowToast(true);
                return
            } else {
                scheduleInput['cadence'] = cadence
            }
        } else if (activeSelection === 1) {
            // Window
            // if (!(toHourSelected || toDaySelected || toMinuteSelected
            //     || fromDaySelected || fromHourSelected || fromMinuteSelected)) {
            //     console.log("no operation");
            //     return
            // }

            scheduleInput['dayStart'] = Weekdays.indexOf(fromDaySelected);
            scheduleInput['dayEnd'] = Weekdays.indexOf(toDaySelected);
            scheduleInput['hourStart'] = fromHourSelected
            scheduleInput['hourEnd'] = toHourSelected
            scheduleInput['minuteStart'] = fromMinuteSelected;
            scheduleInput['minuteEnd'] = toMinuteSelected;
        } else {
            return
        }

        if (Object.keys(selectedRule).length > 0) {
            scheduleInput["modified"] = true;
            scheduleInput["schedule_id"] = selectedRule.schedule_id;
        }

        const data = await client.postSchedule(accessToken, scheduleInput);
        if (data[0] >= 400) {
            console.log("oops");
        }

        if (Object.keys(selectedRule).length > 0) {
            setSuccessString("Successfully modified charging rule!");
        } else {
            setSuccessString("Successfully added new charging rule!");
        }
        setShowSucess(true);
        setSelectedBsm([]);
        setSelectedReceivers([]);
        setSelectedChargeType([]);
        setActiveSelection(99);
        setSelectedRule({})
        setRefreshRules(true);
        setOpenModal(false);

    }

    useEffect(() => {
        if (Object.keys(selectedRule).length > 0) {
            console.log(selectedRule);
            setSelectedBsm(selectedRule.chargers.map(String));
            setSelectedReceivers(selectedRule.receivers.map(String))
            setSelectedChargeType([selectedRule.charge_type]);

            if (selectedRule.dayEnd) {
                setActiveSelection(1);
                setFromDaySelected(Weekdays[selectedRule.dayStart]);
                setToDaySelected(Weekdays[selectedRule.dayEnd]);
                setFromHourSelected(selectedRule.hourStart);
                setToHourSelected(selectedRule.hourEnd);
                setFromMinuteSelected(selectedRule.minuteStart);
                setToMinuteSelected(selectedRule.minuteEnd);

            } else if (selectedRule.cadence) {
                setActiveSelection(0);
                setCadence(selectedRule.cadence);
            } else {
                setActiveSelection(2);
            }
        }

    }, [selectedRule])

    function handleResetStates() {
        setSelectedBsm([]);
        setSelectedReceivers([]);
        setSelectedChargeType([]);
        setActiveSelection(99);
    }

    return (
        <Modal show={openModal} onHide={() => { setOpenModal(false); setSelectedRule({}); handleResetStates() }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {Object.keys(selectedRule).length > 0 ? "Modifying Rule" : "Adding Rule"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-full h-full gap-x-4 grid grid-cols-3 grid-rows-1">
                <Card style={{ height: "60vh" }}>
                    <Card.Body className="w-full h-full flex flex-col max-h-full overflow-y-auto">
                        <Card className="max-h-[50%] min-h-[50%]">
                            <Card.Header className="flex flex-row justify-between content-center items-center">
                                Select Receivers
                                <Button size="sm" onClick={handleSelectAllReceivers}>
                                    select all
                                </Button>
                            </Card.Header>
                            <Card.Body className="border-2 rounded p-2 w-full h-full flex flex-col overflow-y-auto gap-2">
                                {
                                    allOvrs.map((ovr) => {
                                        return (
                                            <CheckBoxWithText
                                                text={'Reciever ' + String(ovr)}
                                                sn={`${ovr}`}
                                                selectedBsm={selectedReceivers}
                                                setterFunc={setSelectedReceivers}
                                            />
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                        <Card className="max-h-[50%] min-h-[50%]">
                            <Card.Header className="flex flex-row justify-between content-center items-center">
                                Select Chargers
                                <Button size="sm" onClick={handleSelectAllBsms}>
                                    select all
                                </Button>
                            </Card.Header>
                            <Card.Body className="border-2 rounded p-2 w-full h-full flex flex-col overflow-y-auto gap-2">
                                {
                                    allBsms.map((bsm) => {
                                        return (
                                            <CheckBoxWithText
                                                text={'Charger ' + String(bsm)}
                                                sn={`${bsm}`}
                                                selectedBsm={selectedBsm}
                                                setterFunc={setSelectedBsm}
                                            />
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
                <Card style={{ height: "60vh" }}>
                    <Card.Body className="flex flex-col gap-y-6">
                        <h3>Select Charge Type </h3>
                        {
                            ChargeType.map((c) => {
                                return (
                                    <CheckBoxWithTexSingular
                                        text={c[1]}
                                        selectedValue={selectedChargeType}
                                        setterFunc={setSelectedChargeType}
                                        value={c[0]}
                                    />
                                )
                            })
                        }
                    </Card.Body>
                </Card>
                <Card style={{ height: "60vh" }}>
                    <Card.Body className="flex flex-col gap-y-10">
                        <h3>Select Charge Execution Type</h3>
                        <div className="flex flex-row h-10 max-h-10">
                            <Button variant={activeSelection === 0 ? "info" : "outline-info"} size="sm" onClick={() => setActiveSelection(0)}>
                                <>
                                    {
                                        activeSelection === 0 ? (<CheckCircle />) : (<Circle />)
                                    }
                                </>
                            </Button>
                            <div className="px-2 flex items-center">
                                <div className="flex flex-col w-full h-full">
                                    <p>
                                        Execute this charge every
                                    </p>
                                    <div>
                                        <Dropdown className="w-2 max-h-2">
                                            <Dropdown.Toggle className='justify-center items-center flex content-center' disabled={activeSelection !== 0}>
                                                {
                                                    activeSelection === 0 && cadence ? `${cadence} charges` : "_ charges"
                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Header>
                                                    <Form.Control type="text" onDoubleClick={(e) => setCadence(e.target.value)} />
                                                </Dropdown.Header>
                                                <Dropdown.Item eventKey="2" onClick={() => setCadence(2)}>2</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={() => setCadence(3)}>3</Dropdown.Item>
                                                <Dropdown.Item eventKey="4" onClick={() => setCadence(4)}>4</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row h-10 max-h-10 mt-4">
                            <Button variant={activeSelection === 1 ? "info" : "outline-info"} size="sm" onClick={() => { setActiveSelection(1); setCadence(undefined) }}>
                                <>
                                    {
                                        activeSelection === 1 ? (<CheckCircle />) : (<Circle />)
                                    }
                                </>
                            </Button>
                            <div className="px-2 flex flex-col">
                                <div>
                                    Execute this charge between
                                </div>
                                <WeekdayAndTimePicker
                                    daySelected={fromDaySelected}
                                    daySetterFunc={setFromDaySelected}
                                    hourSelected={fromHourSelected}
                                    hourSetterFunc={setFromHourSelected}
                                    minuteSelected={fromMinuteSelected}
                                    minuteSetterFunc={setFromMinuteSelected}
                                    disabled={activeSelection !== 1}
                                />
                                <div>
                                    and
                                </div>
                                <WeekdayAndTimePicker
                                    daySelected={toDaySelected}
                                    daySetterFunc={setToDaySelected}
                                    hourSelected={toHourSelected}
                                    hourSetterFunc={setToHourSelected}
                                    minuteSelected={toMinuteSelected}
                                    minuteSetterFunc={setToMinuteSelected}
                                    disabled={activeSelection !== 1}
                                />
                            </div>

                        </div>
                        <div className="flex flex-row mt-16">
                            <Button variant={activeSelection === 2 ? "info" : "outline-info"} size="sm" onClick={() => { setActiveSelection(2); setCadence(undefined) }}>
                                <>
                                    {
                                        activeSelection === 2 ? (<CheckCircle />) : (<Circle />)
                                    }
                                </>
                            </Button>
                            <div className="px-2 flex items-center">
                                Execute this charge as default
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Toast style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }} show={showToast} onClose={toggleShowToast} bg='danger' animation delay={3000} autohide>
                    <Toast.Header style={{ textAlign: 'center' }} className="rounded text-center w-full">
                        <strong className="text-center me-auto"></strong>
                    </Toast.Header>
                    <Toast.Body>
                        <b> {toastText} </b>
                    </Toast.Body>
                </Toast>
                <Button onClick={() => setOpenModal(false)}>
                    Close
                </Button>
                <Button variant='success' onClick={handleModalSubmit}
                    disabled={selectedBsm.length === 0 ||
                        selectedReceivers.length === 0 ||
                        selectedChargeType.length === 0 ||
                        ![0, 1, 2].includes(activeSelection)}>
                    Add Rule
                </Button>
            </Modal.Footer>

        </Modal>
    )

}

export default function SchedulerPage() {
    const [openModal, setOpenModal] = useState(false);
    const [selectedBsm, setSelectedBsm] = useState([]);
    const [selectedReceivers, setSelectedReceivers] = useState([]);
    const [selectedChargeType, setSelectedChargeType] = useState([]);
    const [accessToken, setAccessToken] = useState(TokenService.getToken());
    const [allBsms, setAllBsms] = useState([]);
    const [allOvrs, setAllOvrs] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successString, setSuccessString] = useState("");
    const [showEditSuccess, setShowEditSuccess] = useState(false);
    const [allRules, setAllRules] = useState([]);
    const [refreshRules, setRefreshRules] = useState(false);
    const [selectedRule, setSelectedRule] = useState({});
    const toggleShowSuccess = () => setShowSuccess(!showSuccess);
    const toggleShowEditSuccess = () => setShowEditSuccess(!showEditSuccess);
    const navigate = useNavigate();
    const { userProfile, addUser, removeUser } = useAuthStore();
    if (!userProfile) {
        navigate("/");
    }
    const handleTokenChange = () => {
        setAccessToken(TokenService.getToken());
    }
    TokenService.addTokenChangeListener(handleTokenChange);

    useEffect(() => {

        if (accessToken) {
            client.getAllBsms(accessToken).then((data) => {
                setAllBsms(data);
            })

        }

    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            client.getAllOvrs(accessToken).then((data) => {
                setAllOvrs(data);
            })
        }
    }, [allBsms])

    useEffect(() => {
        if (accessToken) {
            client.getAllRules(accessToken).then((data) => {
                setAllRules(data);
            })
        }
    }, [allOvrs])


    useEffect(() => {
        if (refreshRules) {
            client.getAllRules(accessToken).then((data) => {
                setAllRules(data);
            })
        }
        setRefreshRules(false);

    }, [refreshRules])


    return (
        <div className="w-full h-full gap-4">
            <CustomNavbar name={"Fleet Schedule"} />
            <Toast style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }} show={showSuccess} onClose={toggleShowSuccess} bg='success' animation delay={2000} autohide>
                <Toast.Body className="text-3xl">
                    {successString}
                    {/* Successfully added new charging rule! */}
                </Toast.Body>
            </Toast>
            <div className="flex flex-row w-full gap-2 p-6 flex-wrap" style={{ height: "90vh" }}>
                <Card className="w-full h-full flex flex-col overflow-y-auto max-h-full">
                    <Card.Body className="overflow-y-auto bg-gray-100 rounded">
                        <ListGroup className="gap-y-2">
                            {allRules.map((rule, idx) => {
                                return (
                                    <>
                                        <CollapsableListGroupItem
                                            rule={rule}
                                            setOpenModal={setOpenModal}
                                            setSelectedRule={setSelectedRule}
                                            accessToken={accessToken}
                                            setRefreshRules={setRefreshRules}
                                            setShowSuccess={setShowSuccess}
                                            setSuccessString={setSuccessString}
                                        />
                                    </>
                                )
                            })}

                        </ListGroup>
                    </Card.Body>
                    <Card.Footer className="items-end justify-end flex flex-row">
                        <Button className="h-10" onClick={() => setOpenModal(true)}>
                            Create New Rule
                        </Button>
                    </Card.Footer>
                </Card>
                <AddRuleModal openModal={openModal}
                    setOpenModal={setOpenModal}
                    selectedReceivers={selectedReceivers}
                    setSelectedReceivers={setSelectedReceivers}
                    selectedBsm={selectedBsm}
                    setSelectedBsm={setSelectedBsm}
                    selectedChargeType={selectedChargeType}
                    setSelectedChargeType={setSelectedChargeType}
                    allBsms={allBsms}
                    allOvrs={allOvrs}
                    accessToken={accessToken}
                    setShowSucess={setShowSuccess}
                    setRefreshRules={setRefreshRules}
                    selectedRule={selectedRule}
                    setSelectedRule={setSelectedRule}
                    setSuccessString={setSuccessString} />
            </div>
        </div>
    )


}