import React, { useState, useEffect } from "react";
import CustomNavbar from "../components/navbar";
import { Button, Card } from "react-bootstrap";
import { Form, Spinner } from "react-bootstrap";
import { CheckBoxWithText } from "../components/checkboxWithText";
import { LiveStatusComponent, BsmStates, bsmColors, reverseMappingBsmState, customBsmMapping } from "../components/liveStatusComponent";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Timeline } from "../components/timelineHistory";
import { Plus, Minus, RefreshCcw } from "lucide-react";
import { useAuthStore } from "../store/authStore";
import FastAPIClient from "../utils/client";
import TokenService from "../utils/token";
import { useNavigate } from "react-router-dom";

const client = new FastAPIClient();
const STARTING_RADIUS = 175;
// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     return (
//         <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//             {`${(percent * 100).toFixed(2)}%`}
//         </text>
//     );
// };



function formatDate(inputDate) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    let monthString = month.toString();
    if (month < 10) {
        monthString = "0" + monthString;
    }

    const day = inputDate.getDate();
    let dayString = day.toString();
    if (day < 10) {
        dayString = "0" + dayString;
    }

    return `${year}-${monthString}-${dayString}`

}

function getDefaultDates() {
    let currentDate = new Date();
    const dateString = formatDate(currentDate);

    let thirtyDaysAgo = new Date(new Date().setDate(currentDate.getDate() - 1));
    const fromDateString = formatDate(thirtyDaysAgo);

    return [dateString,
        fromDateString,
    ]

}

function handleDateChange(dateInput, setterFunc) {

    const newDate = new Date(dateInput);

    setterFunc(formatDate(newDate));

}

function formatPieChartData(pie_chart_data_raw) {

    const bsmStates = Object.keys(pie_chart_data_raw);
    const formattedPieData = [];

    for (const bsmState of bsmStates) {

        formattedPieData.push({
            name: BsmStates[bsmState],
            value: pie_chart_data_raw[bsmState]
        })
    }

    return formattedPieData;

}


export default function CsPage() {

    const [todayDate, thirtyDaysAgo] = getDefaultDates();

    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(thirtyDaysAgo);

    const [accessToken, setAccessToken] = useState(TokenService.getToken());

    const [stateHistory, setStateHistory] = useState({});
    const [outerRadius, setOuterRadius] = useState(STARTING_RADIUS);
    const [selectedBsm, setSelectedBsm] = useState([]);
    const [latestDataMap, setLatestDataMap] = useState({});
    const [pieData, setPieData] = useState({});
    const [totalCounterData, setTotalCounterData] = useState({});
    const [timeTotal, setTimeTotal] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const { userProfile, addUser, removeUser } = useAuthStore();
    const navigate = useNavigate();
    if (!userProfile) {
        navigate("/");
    }

    const handleTokenChange = () => {
        setAccessToken(TokenService.getToken());
    }
    TokenService.addTokenChangeListener(handleTokenChange);

    useEffect(() => {

        if (accessToken) {
            let fromDateEpoch = new Date(fromDate);

            fromDateEpoch.setHours(23, 59, 59);
            fromDateEpoch = fromDateEpoch.getTime();

            let toDateEpoch = new Date();
            if (toDate !== null) {
                toDateEpoch = new Date(toDate);
                toDateEpoch.setDate(toDateEpoch.getDate() + 2);
                toDateEpoch.setHours(23, 59, 59);
            }
            toDateEpoch = toDateEpoch.getTime();

            client.getBsmHistory(accessToken, fromDateEpoch, toDateEpoch)
                .then((data) => {
                    // const pieChartData = formatPieChartData(data["pie_chart_data"]);
                    // setPieData(pieChartData);
                    // const totalVal = Object.values(data["pie_chart_data"]).reduce((partialSum, a) => partialSum + a, 0);
                    // setTimeTotal(totalVal);

                    setTotalCounterData(data["sn_counter_dict"]);

                    delete data["pie_chart_data"];
                    delete data["sn_counter_dict"];
                    const serialNumbers = Object.keys(data);
                    const serialNumbersWithData = [];


                    const latestDataKeyLength = Object.keys(latestDataMap).length
                    let updateLatestData = false;
                    for (const sn of serialNumbers) {
                        if (latestDataKeyLength === 0) {
                            const latest_data = data[sn]["latest"];
                            latestDataMap[sn] = latest_data;
                            updateLatestData = true;
                        }
                        delete data[sn]["latest"];

                        if (Object.keys(data[sn]).length > 0) {
                            serialNumbersWithData.push(sn)
                        }

                    }
                    if (updateLatestData) {
                        setLatestDataMap(latestDataMap)
                    }

                    setSelectedBsm(serialNumbersWithData);
                    setStateHistory(data);
                    setIsLoading(false);

                })
        }
    }, [accessToken, fromDate, toDate]
    )

    useEffect(() => {

        const tempPieData = {}
        let tempTimeTotal = 0
        for (const sn of selectedBsm) {
            const snData = totalCounterData[sn];
            const tempBsmStates = Object.keys(snData);

            if (tempBsmStates === 0) {
                continue
            }
            for (const tbsmState of tempBsmStates) {
                var newBsmState = tbsmState;

                if (tbsmState in customBsmMapping) {
                    newBsmState = customBsmMapping[tbsmState];
                }

                if (!(newBsmState in tempPieData)) {
                    tempPieData[tbsmState] = 0
                }

                tempPieData[newBsmState] += snData[tbsmState];
                tempTimeTotal += snData[tbsmState]
            }
        }

        setPieData(formatPieChartData(tempPieData));
        setTimeTotal(tempTimeTotal);
    }, [selectedBsm]


    )

    const renderColorfulLegendText = (value, entry) => {

        if (value === "Charging") {
            entry['color'] = "#c8dc2a"
        }
        const { color } = entry;

        return <span style={{ color }}>{value}</span>;
    };

    const renderCustomTooltip = ({ payload }) => {
        return (
            <div className="bg-white flex border rounded justfiy-center items-center p-2 text-center">
                {payload[0]?.name} {((payload[0]?.value / timeTotal) * 100).toFixed(2)}%
            </div>
        )
    }

    const incrementOuterRadius = () => {
        setOuterRadius(outerRadius + 10);
    }
    const decrementOuterRadius = () => {
        setOuterRadius(outerRadius - 10);
    }
    const resetOuterRadius = () => {
        setOuterRadius(STARTING_RADIUS);
    }

    return (
        <div className="w-full h-full gap-2">
            <CustomNavbar name={""} />
            <div className="flex flex-row w-full gap-2 px-2 py-2 flex-wrap" style={{ height: "85vh" }}>

                <div className="w-full h-3/4 grid grid-cols-3 grid-rows-1 gap-2 ">

                    <Card className="flex flex-col p-2 shadow-md animate-fade hover:shadow-xl">
                        <Card.Title>
                            Equipment Effectiveness
                        </Card.Title>
                        <Card.Body className="flex flex-row justify-center items-center text-center">
                            {isLoading ? <Spinner /> : (

                                <>
                                    <ResponsiveContainer width="90%" height="90%">
                                        <PieChart width={500} height={500}>
                                            <defs>
                                                <pattern fill="#c8dc2a" id="pattern-checkers" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                                                    <circle cx="0" cy="0" r="100" stroke="black" fill="#c8dc2a" stroke-width="1" />
                                                    <line x1="0" x2="100" y1="0" y2="100" stroke="grey" stroke-width="1" />
                                                </pattern>
                                            </defs>
                                            <Pie
                                                data={pieData}
                                                cx="50%"
                                                cy="50%"
                                                // labelLine={true}
                                                // label={renderCustomizedLabel}
                                                outerRadius={outerRadius}
                                                dataKey="value"
                                            >
                                                {pieData.map((entry, index) => {
                                                    let fill = `#${bsmColors[reverseMappingBsmState[entry["name"]]]}`;
                                                    if (entry["name"].includes("Charging")) {

                                                        fill = 'url(#pattern-checkers)';
                                                    }

                                                    return (<Cell key={`cell-${index}`} fill={fill} />)
                                                })}
                                            </Pie>
                                            <Tooltip
                                                content={renderCustomTooltip}
                                            />
                                            <Legend formatter={renderColorfulLegendText} />
                                        </PieChart>

                                    </ResponsiveContainer>
                                    <div className="flex flex-col gap-2">
                                        <Button onClick={incrementOuterRadius}>
                                            <Plus />
                                        </Button>
                                        <Button onClick={decrementOuterRadius}>
                                            <Minus />
                                        </Button>
                                        <Button onClick={resetOuterRadius}>
                                            <RefreshCcw />
                                        </Button>
                                    </div>
                                </>
                            )}

                        </Card.Body>
                    </Card>
                    <Card className="flex flex-col p-2 shadow-md animate-fade hover:shadow-xl">
                        <Card.Title>
                            Live Charger State
                        </Card.Title>
                        <Card.Body className="flex flex-col h-full overflow-y-auto gap-y-4 border">

                            {Object.keys(latestDataMap) === 0 ? (<>Loading</>) :

                                (
                                    Object.keys(selectedBsm).map((idx) => {
                                        const sn = selectedBsm[idx]
                                        return (<LiveStatusComponent
                                            sn={sn}
                                            latestDataMap={latestDataMap} />)
                                    })
                                )
                            }

                        </Card.Body>
                    </Card>
                    <Card className="flex flex-col p-2 shadow-md animate-fade hover:shadow-xl">
                        <Card.Title>
                            Data Selection
                        </Card.Title>
                        <Card.Body className="h-full flex flex-col items-center text-center">
                            <div className="w-full overflow-y-auto border" style={{ height: "100%" }}>

                                <div className="flex flex-col gap-2 overflow-y-auto" style={{ height: "100%" }}>
                                    {
                                        Object.keys(stateHistory).map((sn) => {
                                            return (<CheckBoxWithText text={`Charger #${sn}`}
                                                selectedBsm={selectedBsm}
                                                setterFunc={setSelectedBsm}
                                                sn={sn} />)
                                        })
                                    }
                                </div>
                            </div>
                            <div className="h-1/4">
                                Data Range
                                <div className="flex flex-row flex-nowrap justify-center px-2 gap-2">
                                    <div className="flex flex-col">
                                        From
                                        <Form.Control type="date" defaultValue={fromDate} onChange={(value) => handleDateChange(value.target.value, setFromDate)} />
                                    </div>
                                    <div>
                                        To
                                        <Form.Control type="date" defaultValue={todayDate} max={todayDate} onChange={(value) => handleDateChange(value.target.value, setToDate)} />
                                    </div>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className="w-full h-1/4 flex flex-col">
                    <Card className="flex flex-col h-full p-2 shadow-md animate-fade-slow hover:shadow-xl">
                        <Card.Title>
                            State History
                        </Card.Title>
                        <Card.Body className="flex flex-col h-full gap-y-4">
                            {isLoading ? <Spinner /> :
                                (
                                    (selectedBsm).map((sn) => {
                                        return (<Timeline sn={sn} stateHistory={stateHistory} />)
                                    })
                                )}
                        </Card.Body>
                    </Card>
                </div>

            </div >
        </div >
    );
}
