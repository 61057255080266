import React from "react";
import DataTable from "../components/parentTable";
import CustomNavbar from "../components/navbar";
import { useAuthStore } from "../store/authStore";
import { Navigate } from "react-router-dom";

export default function Data() {

  return (
    <div className="w-full h-full">
      <CustomNavbar name={"Data"} />
      <div className="border-8 border-black">
      <DataTable />
      </div>
    </div>
  );
}
