import React from "react";
import { Outlet } from "react-router-dom";
import CustomNavbar from "./navbar";
import { useAuthStore } from "../store/authStore";
import LandingPage from "../pages/landingPage";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import TokenService from "../utils/token";
import jwt_decode from "jwt-decode";
const Layout = () => {

  const { userProfile, addUser, removeUser } = useAuthStore();

  return (
    <div className="h-screen w-full">
      <CustomNavbar />
      {(userProfile.tk && userProfile.tk !== "invalid") ?
        (
          <LandingPage />
        ) :
        (<div style={{ margin: 200, textAlign: "center", justifyContent: "center", flex: 1 }}>
          <Navigate to='/login' />;
        </div>)}
      <Outlet />
    </div>
  );
};

export default Layout;
