import React from "react";

import { Link } from "react-router-dom";

import { useAuthStore } from "../store/authStore";
export default function UnauthPage() {
  const { removeUser } = useAuthStore();

  return (
    <h1 style={{ color: "#212226" }}>
      {" "}
      <Link style={{ color: "#212226" }} to={{ pathname: "/login" }} onClick={removeUser}>
        UNAUTHORIZED USER DETECTED. You have been logged out. Click here to log back in!{" "}
      </Link>{" "}
    </h1>
  );
}
