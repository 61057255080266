import React, {useState, useRef, useEffect} from "react";
import { Button, Image, Form, FloatingLabel, Card, Spinner, Alert } from "react-bootstrap";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { fetchGoogleResponse } from "../utils/auth";
import { useAuthStore } from "../store/authStore";
import FadeIn from 'react-fade-in';
import FastAPIClient from "../utils/client";
import { useNavigate, useParams } from "react-router-dom";
import { validatePasswords } from "./signup";
// TODO:
// ADD SIGN UP FAILURE MSG 
// ADD SIGN UP SUCCESS MSG UPON REDIRECT

const logo = require("../photos/Resonant-Link-Logo_White.png")
const client = new FastAPIClient();

export default function PasswordResetPage() {
    let params = useParams();
    const [pwErrorStr, setPwErrorStr] = useState("");
    const [ isLoading, setLoading ] = useState(false);
    const [validated, setValidated] = useState(false);
    const [ showEmailSuccess, setShowEmailSuccess ] = useState(false);
    const [ showEmailFailed, setShowEmailFailed ] = useState(false);
    const [ emailInvalidString, setEmailInvalidString ] = useState("");
    const [isPasswordInvalid, setisPasswordInvalid] = useState(false);
    const [ isTokenValid, setIsTokenValid ] = useState(false);
    const [ showTokenExpired, setShowTokenExpired ] = useState(params.forgetToken === "expired");
    const navigate = useNavigate();
    const onFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const email = (e.target.floatingInput.value);
        if (!email){
            setValidated(true)
            setLoading(false)
        }
        const resp = await client.postSendForgetEmail(email)
        if (resp.status_code === 200){
            setShowEmailSuccess(true)
            setTimeout( ()=>{setShowEmailSuccess(false)}, 5000)
            setLoading(false)
        } else {
            setShowEmailFailed(true)
            setLoading(false)
            setEmailInvalidString(resp.data.detail)
            setTimeout( ()=>{setShowEmailFailed(false)}, 5000)
        }
        setLoading(false)
      }
    const handleChange = () => {
        setisPasswordInvalid(false);
    }

    const onFormPasswordSubmit = async ( e ) => {
        setLoading(true)
        e.preventDefault();
        const password = (e.target.floatingPassword.value);
        const confirmPassword = (e.target.floatingPasswordConfirm.value);
        const [result,resString] = validatePasswords(password,confirmPassword)
        if (result){
            setPwErrorStr(resString)
            setisPasswordInvalid(result)
            setLoading(false)
            return
        }
        const resp = await client.postResetPassword(password, params.forgetToken)
        setLoading(false)
        if (resp.data.status_code === 200){
            navigate("/login/pw")
        }
        else {
            setisPasswordInvalid(true)
            setPwErrorStr("Password Cannot be the same as old")
            setLoading(false)
        }
    }

    async function validateToken() {
        let response = await client.validateForgetToken(params.forgetToken);
        let data = await response;
        if (data.status_code === 200){
            setIsTokenValid(true);
            return
        }
        else {
            setShowTokenExpired(true);
            navigate("/password_reset/expired")
        }
    }

    useEffect( () => {
        if (!params.forgetToken || params.forgetToken === "expired"){
            return
        }
        
        validateToken()
    },[])
    setTimeout(()=>{setShowTokenExpired(false)},3000)
    return (
    <div className="flex h-screen items-center w-full">
                <style type="text/css">
        {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
      </style>
        <div className="w-1/2 h-full bg-resBlack items-center justify-center rounded-r">
            <div className="flex h-full w-full justify-center items-center">
                <div className="w-1/2"> 
                <li><a href='/'>
                <Image src={logo} fluid/>
                </a>
                </li>
                </div>
            </div>
        </div>

        

        <div className="w-1/2 h-full items-ceneter justify-center rounded-l-lg">
            <div className="flex h-full w-full justify-center items-center">
                <div className="w-1/2">
                    <FadeIn>
                    { showTokenExpired?  (<Alert variant="danger">Link Not Valid or Expired, Please try again.</Alert>) : (<></>)}
                    <Card>
                        <Card.Body>
                            {isTokenValid ? (<>
                            <Card.Title className="text-center mb-3"> Enter New Password </Card.Title>
                            <Form noValidate validated={validated} onSubmit={onFormPasswordSubmit}> 
                            <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                                <Form.Control isInvalid={isPasswordInvalid} required type="password" placeholder="Password" onChange={handleChange}/>
                                <Form.Control.Feedback type="invalid">
                                    {pwErrorStr}
                                </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPasswordConfirm" label="Confirm Password">
                                <Form.Control isInvalid={isPasswordInvalid} required type="password" placeholder="Password" onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">
                                    {pwErrorStr}
                                </Form.Control.Feedback>
                        </FloatingLabel>
                        <div className="text-center">
                        <Button variant="res" 
                        type="submit" 
                        className="mt-3 w-3/4"
                        disabled={isLoading}
                        >
                            {isLoading ? <Spinner animation="border"/> : "Change Password" }
                        </Button>
                    
                        </div>
                        </Form>                                
                        </>)  
                        : (<>
                            {showEmailSuccess ? <Alert variant="success"> Email has been successfully sent! </Alert> : <></>}
                            {showEmailFailed ? <Alert variant="danger"> {emailInvalidString} </Alert> : <></>}
                            <Card.Title className="text-center mb-3" style={{fontWeight:'bold'}}> Reset Password  </Card.Title>
                            <>Please enter your email address to recieve instructions on how to reset your password.</>

                            <Form noValidate validated={validated} onSubmit={onFormSubmit}> 
                            <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="mb-3 mt-3"
                                >
                                <Form.Control required type="email" placeholder="name@example.com" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address
                                </Form.Control.Feedback>
                                
                                </FloatingLabel>
                            <div className="text-center">
                            <Button variant="res" 
                            type="submit" 
                            className="mt-2 w-3/4"
                            disabled={isLoading}
                            >
                                {isLoading ? <Spinner animation="border"/> : "Send Reset Instructions" }
                            </Button>
                            </div>
                            </Form>                        
                        </>)}
                        </Card.Body>
                </Card>
                </FadeIn>
                </div>
            </div>
        </div>
    </div>
    )
    ;
  }
  