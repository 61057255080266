import React from "react";
import { Dropdown } from "react-bootstrap";
import { Weekdays } from "../utils/const";

function generateArray(low, high) {
    const list = [];
    for (var i = low; i <= high; i++) {
        list.push(i);
    }
    return list;
}

const minutesArray = generateArray(0, 59)
const hoursArray = generateArray(0, 23)

export function WeekdayAndTimePicker({
    daySelected,
    hourSelected,
    minuteSelected,
    daySetterFunc,
    hourSetterFunc,
    minuteSetterFunc,
    disabled }) {



    return (
        <>
            <div className="flex flex-row">
                <Dropdown>
                    <Dropdown.Toggle disabled={disabled}>
                        {daySelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            Weekdays.map((day) => {
                                return (
                                    <Dropdown.Item eventKey={day} onClick={() => daySetterFunc(day)}> {day} </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle disabled={disabled}>
                        {hourSelected < 10 ? `0${hourSelected}` : hourSelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="overflow-y-scroll max-h-72">
                        {
                            hoursArray.map((hour) => {

                                return (
                                    <Dropdown.Item eventKey={hour} onClick={() => hourSetterFunc(hour)}> {hour < 10 ? `0${hour}` : hour} </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle disabled={disabled}>
                        {minuteSelected < 10 ? `0${minuteSelected}` : minuteSelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="overflow-y-scroll max-h-72">
                        {
                            minutesArray.map((mins) => {
                                return (
                                    <Dropdown.Item eventKey={mins} onClick={() => minuteSetterFunc(mins)}> {mins < 10 ? `0${mins}` : mins} </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}