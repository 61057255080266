import React from "react";
import { Button } from "react-bootstrap";
import { Circle, CheckCircle } from "lucide-react";

export function CheckBoxWithText({ text, selectedBsm, setterFunc, sn }) {
    const handleToggle = () => {

        if (!!selectedBsm.includes(sn)) {
            const idx = selectedBsm.indexOf(sn);
            selectedBsm.splice(idx, 1);
        } else {
            selectedBsm.push(sn);
            let numArray = selectedBsm.map(Number);
            numArray.sort(function (a, b) { return a - b });
            selectedBsm = [...numArray.map(String)];
        }
        setterFunc([...selectedBsm]);
    }
    return (

        <>
            <div className="flex flex-row">
                <Button variant={selectedBsm.includes(sn) ? "info" : "outline-info"} size="sm" onClick={() => handleToggle()}>
                    <>
                        {
                            selectedBsm.includes(sn) ? (<CheckCircle />) : (<Circle />)
                        }
                    </>
                </Button>
                <div className="px-2 flex items-center">
                    {text}
                </div>
            </div>
        </>
    )
}

export function CheckBoxWithTexSingular({ text, selectedValue, setterFunc, value }) {

    const handleToggle = () => {

        if (!!selectedValue.includes(value)) {
            const idx = selectedValue.indexOf(value);
            selectedValue.splice(idx, 1);
        } else {
            selectedValue = [value];
        }
        setterFunc([...selectedValue]);
    }
    return (

        <>
            <div className="flex flex-row">
                <Button variant={selectedValue.includes(value) ? "info" : "outline-info"} size="sm" onClick={() => handleToggle()}>
                    <>
                        {
                            selectedValue.includes(value) ? (<CheckCircle />) : (<Circle />)
                        }
                    </>
                </Button>
                <div className="px-2 flex items-center">
                    {text}
                </div>
            </div>
        </>
    )
}
