import useAuthStore from "../store/authStore"


const TokenService = {
    setToken: (token) => {
        const stateObj = JSON.parse(localStorage.getItem("auth"))
        stateObj["state"]["userProfile"]["aT"] = token;
        localStorage.setItem("auth", JSON.stringify(stateObj));
    },
    setRefreshToken: (token) => {
        const stateObj = JSON.parse(localStorage.getItem("auth"))
        stateObj["state"]["userProfile"]["tk"] = token;
        localStorage.setItem("auth", JSON.stringify(stateObj));
    },
    getToken: () => {
        const stateObj = JSON.parse(localStorage.getItem("auth"))["state"]["userProfile"]
        //const refresh_token = stateObj["tk"];
        const access_token = stateObj["aT"];
        return access_token
    },
    getRefreshToken: () => {
        const stateObj = JSON.parse(localStorage.getItem("auth"))["state"]["userProfile"]
        const refresh_token = stateObj["tk"];
        return refresh_token
    },
    notifyTokenChanged: () => {
        window.postMessage({ event: 'tokenChanged' }, window.location.origin);
    },

    addTokenChangeListener: (listener) => {
        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin && event.data.event === 'tokenChanged') {
                listener();
            }
        });

    }
}
export default TokenService;