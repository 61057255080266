import React, { useEffect, useState } from "react";
import { Image, Card, Button, Spinner, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import jwt_decode from "jwt-decode";
import FastAPIClient from "../utils/client";


const client = new FastAPIClient();
const logo = require("../photos/Resonant-Link-Logo_White.png")
export default function VerifyPage(props) {
    const params = useParams();
    const navigate = useNavigate();
    const { userProfile, addUser, removeUser } = useAuthStore();
    const [loading, setLoading] = useState(false);
    const [emailSentSuccess, setEmailSentSuccess] = useState(false);
    const [showFailedBanner, setShowFailedBanner] = useState(false);
    const [showVerifyBannerFailed, setShowVerifyBannerFailed] = useState(false);
    useEffect(() => {
        if (userProfile.verified) {
            navigate("/?source=verify")
        }
    }, [userProfile.verified])

    const handleClick = async () => {
        const resp = await client.validateVerificationToken(params.verifyToken);
        if (resp.status_code === 200) {
            userProfile.verified = true;
            addUser(userProfile);
        } else {
            setShowVerifyBannerFailed(true);
            setTimeout(() => { setShowVerifyBannerFailed(false) }, 4000);
        }

    }

    const handleSendEmail = async () => {
        setLoading(true);
        const decoded = jwt_decode(userProfile.tk);
        const email = decoded.sub;
        const resp = await client.postSendVerificationEmail(email);
        if (resp.status_code === 200) {
            setEmailSentSuccess(true);
        } else {
            setShowFailedBanner(true);
            setTimeout(() => { setShowFailedBanner(false) }, 4000);
        }
        setLoading(false);
    }

    return (
        <div className="flex h-screen items-center w-full">
            <style type="text/css">
                {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
            </style>
            <div className="w-1/2 h-full bg-resBlack items-center justify-center rounded-r hidden md:flex">
                <div className="flex h-full w-full justify-center items-center">
                    <div className="w-1/2">
                        <li><a href='/'>
                            <Image src={logo} fluid />
                        </a>
                        </li>
                    </div>
                </div>
            </div>

            <div className="w-full bg-resWhite h-full items-ceneter justify-center rounded-l-lg md:w-1/2">
                <div className="flex h-full w-full justify-center items-center flex-col">
                    {showFailedBanner === true ? <Alert variant="danger">Something went wrong! Email did not send. Please try again</Alert> : <></>}
                    {showVerifyBannerFailed === true ? <Alert variant="danger">Something went wrong! Try closing this window and retrying the verification process again</Alert> : <></>}
                    <Card className="w-1/2 h-1/2 min-h-1/2">
                        {params.verifyToken ?
                            (<Card.Body className="h-full flex flex-col gap-3 justify-center items-center">

                                <div className="text-center border-b-2">
                                    Please click the button below to verify your account
                                </div>
                                <Button variant="res w-3/4" onClick={handleClick} disabled={loading}>
                                    {loading ?
                                        (<Spinner />)
                                        : "Verify My Account"}
                                </Button>

                            </Card.Body>) :
                            (<Card.Body className="h-full flex flex-col gap-3 justify-center items-center">
                                <div className="text-center">
                                    Welcome!
                                </div>
                                <div className="text-center">
                                    In order to gain access to the dashboard, you must verify your account.
                                </div>
                                <div className="text-center border-b-2">
                                    Please click the button below to send the verification instructions to your email.
                                </div>
                                {emailSentSuccess === true ?
                                    <Button variant="success" disabled={true}>
                                        Email Sent Successfully!
                                    </Button> :
                                    (
                                        <Button variant="res w-3/4" onClick={handleSendEmail} disabled={loading}>
                                            {loading ? (<Spinner />) :
                                                "Send Verification Email"}
                                        </Button>)
                                }
                            </Card.Body>)
                        }
                    </Card>
                    <span className="text-black p-4 text-xs">
                        If you feel like this is a mistake,
                        <a href="/login"> please try logging in again here</a>
                    </span>
                </div>
            </div>
        </div>
    )
        ;
}
