
export function convertTimestamp(timestamp) {
    let myDate = new Date(timestamp);
    let years = myDate.getFullYear().toString();
    let month = myDate.getMonth().toString();
    let date = myDate.getDate().toString();
    let hours = myDate.getHours().toString();
    let minutes = myDate.getMinutes().toString();
    let seconds = myDate.getSeconds().toString();

    if (hours.length === 1) {
        hours = `0${hours}`
    }
    if (minutes.length === 1) {
        minutes = `0${minutes}`
    }
    if (seconds.length === 1) {
        seconds = `0${seconds}`
    }
    let shortDate = `${years}-${month}-${date} ${hours}:${minutes}:${seconds}`

    return shortDate;
}