import axios from "axios";
import TokenService from "./token";

export default class FastAPIClient {
  constructor() {
    this.apiClient = this.getApiClient(); // 2
  }

  async HandleRotation(err) {
    if (err.response.status === 401 &&
      err.response.data["detail"] &&
      err.response.data["detail"].includes("Token expired")) {

      // Token expired lets ask for a new one
      const refresh_token = TokenService.getRefreshToken();

      let response = await this.rotateToken(refresh_token);
      let data = await response.data;
      TokenService.setRefreshToken(data.refresh_token);
      TokenService.setToken(data.access_token);

      TokenService.notifyTokenChanged();

      return true;
    } else if (err.response.status === 500) {
      // dont refresh token, just retry the request.
      return true;
    }

    return false;
  }

  /* Create Axios client instance pointing at the REST api backend */
  getApiClient() {

    let initialConfig = {
      baseURL: (process.env.REACT_APP_ENV === "prod") ? '/api' : `http://localhost:8000/api` // 3
    };
    let client = axios.create(initialConfig);
    return client;
  }

  getAllData(token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get_all_metadata/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getAllData(TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }

  getAllBsms(token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/all_bsm/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getAllBsms(TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }

  getAllOvrs(token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/all_ovr/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getAllOvrs(TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }


  getAllRules(token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/schedules/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getAllRules(TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }


  getBsmHistory(token, fromTime, toTime, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/state_history/end/${fromTime}/start/${toTime}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getBsmHistory(TokenService.getToken(), fromTime, toTime, count + 1);
        } else {
          return err.response.status;
        }
      });
  }


  getTelemetryDataByMetadataFk(id, token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/telemetry/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {


        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getTelemetryDataByMetadataFk(id, TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }

  getMetadataByPk(pk, token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/metadata/${pk}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getMetadataByPk(pk, TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }

  getPlottingTemplate(templateId, token, count = 0) {
    let dbData = null;
    if (count === 2) {
      return [403, "max retries reached"];
    }
    dbData = this.apiClient
      .get(`/get/templateId/${templateId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getPlottingTemplate(templateId, TokenService.getToken(), count + 1);
        } else {
          return err.response;
        }
      });
    return dbData;
  }

  getAllTemplates(token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient
      .get(`/get/allTemplates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.getAllTemplates(TokenService.getToken(), count + 1);
        } else {
          return err.response.status;
        }
      });
  }

  validateForgetToken(token) {
    return this.apiClient
      .get(`/get/validate_forget_token/${token}`)
      .then(({ data }) => {
        return data;
      })
      .catch(function (err) {
        return err.response;
      })
  }

  async validateVerificationToken(token) {
    return this.apiClient
      .get(`/get/validate_verification_token/${token}`)
      .then(({ data }) => {
        return data;
      })
      .catch(function (err) {
        return err;
      })
  }

  async postSendForgetEmail(email) {
    return this.apiClient
      .post('/post/send_forget_password', {
        email: email
      })
      .then(({ data }) => {
        return data;
      }).catch(function (error) {
        return error.response;
      })
  }


  async postSendVerificationEmail(email) {
    return this.apiClient
      .post('/post/send_verification', {
        email: email
      })
      .then(({ data }) => {
        return data;
      }).catch(function (error) {
        return error.response;
      })
  }

  async postResetPassword(password, token) {
    try {
      const data = await this.apiClient
        .post(`/post/reset_password`,
          {
            password: password,
            forget_token: token
          })
      return data
    }
    catch (error) {
      return error.response;
    }
  }

  async postPlottingTemplate(plotDict, layouts, templateId, plotName, email, token, count = 0) {
    if (count === 2) {
      return [403, "max retries reached"];
    }
    return this.apiClient
      .post(`/save_template`,
        {
          layoutDict: layouts,
          plotDict: plotDict,
          templateId: templateId,
          email: email,
          plotName: plotName
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.postPlottingTemplate(
            plotDict,
            layouts,
            templateId,
            plotName,
            email,
            TokenService.getToken(),
            count + 1);
        } else {
          return [err.response.status, err.response.data];
        }
      });
  }

  async postUserInfo(user) {
    return this.apiClient.post("/login", user).then(({ data }) => { return data })
      .catch(function (err) { return err.response })
  }

  async postSchedule(token, scheduleInput, count = 0) {
    if (count === 2) {
      return [403, 'max retries reached']
    }
    return this.apiClient.post(
      '/internal/post/schedule',
      scheduleInput,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(({ data }) => {
      return data;
    }).catch(async (err) => {
      const rotated = await this.HandleRotation(err);
      if (rotated) {
        return this.postSchedule(
          TokenService.getToken(),
          scheduleInput,
          count + 1);
      } else {
        return [err.response.status, err.response.data];
      }
    });
  }

  async deleteRuleById(id, token, count = 0) {
    if (count === 2) {
      return 403;
    }
    return this.apiClient.post(`/internal/delete/schedule/`,
      { schedule_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        return data;
      }).catch(async (err) => {
        const rotated = await this.HandleRotation(err);
        if (rotated) {
          return this.deleteRuleById(id, TokenService.getToken(), count + 1);
        } else {
          console.log(err.response);
          return err.response.status;
        }
      })

  }

  async postSignupUser(user) {
    return this.apiClient.post("/signup", user)
      .then(({ data }) => { return data })
      .catch(function (err) { return err.response });
  }

  async postSendTicket(token, subject, body) {
    return this.apiClient.post("/post/send_ticket_confirmation",
      {
        subject: subject,
        body: body
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(({ data }) => { return data })
      .catch(function (err) { return err.response })
  }

  async rotateToken(token) {
    try {
      const data = await this.apiClient
        .post(`/post/rotate_tokens`,
          { token: token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
      return data
    }
    catch (error) {
      return error.response
    }
  }

  nonAsyncRotateToken(token) {
    try {
      const data = this.apiClient
        .post(`/post/rotate_tokens`,
          { token: token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(({ data }) => { return data })
      return data
    }
    catch (error) {
      return error.response
    }
  }


}
