import jwt_decode from "jwt-decode";
import FastAPIClient from "../utils/client";

const client = new FastAPIClient();

export const fetchGoogleResponse = async (response, addUser) => {
  const decodedReponse = jwt_decode(response.credential);

  const userObject = {
    email: decodedReponse.email,
    sub: decodedReponse.sub,
    name: decodedReponse.name,
  }

  const userResponse = await client.postUserInfo(userObject);
  if (userResponse !== undefined){
    if (userResponse.request.status !== 200){
      userObject["tk"] = "invalid";
    }else{
      userObject["tk"] = userResponse.data.refresh_token;
    }
  }
  
  addUser(userObject);
}

export default fetchGoogleResponse;
