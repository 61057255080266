import { create } from "zustand";
import { persist } from "zustand/middleware";

const authStore = (set) => ({
  userProfile: { tk: null, isAdmin: false, verified: false, aT: null },
  addUser: (user) => set({ userProfile: user }),
  removeUser: () =>
    set({
      userProfile: { tk: null, isAdmin: false, verified: false, aT: null },
    }),
});

export const useAuthStore = create(
  persist(authStore, {
    name: "auth",
  })
);

export default useAuthStore;
