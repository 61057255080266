/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar";
import { useAuthStore } from "../store/authStore";
import { Navigate, useParams } from "react-router-dom";
import FastAPIClient from "../utils/client";
import Plot from 'react-plotly.js';
import { ButtonGroup, Button, ListGroup, ListGroupItem } from "reactstrap";
import { convertTimestamp } from "../utils/utils";
const client = new FastAPIClient();
const PLOT_HEIGHT = 700;

const PlottingPage = () => {

  let params = useParams();
  const [isLoading, setLoading] = useState(true);
  const [dbData, setData] = useState([]);
  const [currentActiveX, setCurrentActiveX] = useState("");
  const [currentActiveY, setCurrentActiveY] = useState("");
  const [xArr, setxArr] = useState([]);
  const [yArr, setyArr] = useState([]);
  const { userProfile } = useAuthStore();
  if (!userProfile.email) {
    return <Navigate to="/unauthorized" replace />;
  }


  useEffect(() => {
    client
      .getTelemetryDataByMetadataFk(params.metadataFK, userProfile.access_token)
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [params.metadataFK, userProfile.access_token]);
  useEffect(() => {
    var x_axis;
    if (currentActiveX === "timestamp") {
      x_axis = dbData.map((item) => convertTimestamp(item[currentActiveX]));
    } else {
      x_axis = dbData.map((item) => item[currentActiveX]);
    }

    let y_axis = dbData.map((item) => item[currentActiveY]);
    setxArr(x_axis);
    setyArr(y_axis);
  }, [dbData, currentActiveX, currentActiveY]
  )
  if (isLoading) {
    return (
      <div>
        <CustomNavbar name={"Loading Data for Plots"} />
        <div>LOADING</div>
      </div>
    );
  }

  const object_keys = Object.keys(dbData[0]).sort();

  const renderListGroupItem = (name, axis) => {
    return (<ListGroupItem active={name === (axis === "x" ? currentActiveX : currentActiveY)}
      href="#"
      key={`${name}`}
      onClick={() => handleClick(name, axis)}
    >{name}</ListGroupItem>)
  }
  function handleClick(name, axis) {
    if (axis === "x") {
      setCurrentActiveX(name);
    } else {
      setCurrentActiveY(name);
    }
  }

  return (
    <div style={{ flex: 1, flexDirection: "row" }}>
      <CustomNavbar name={"Plotting"} />
      <div style={{ flexDirection: "row", flex: 1, float: "left", marginLeft: 10 }}>
        <h3 style={{ textAlign: "center" }}>X - Axis</h3>
        <ListGroup style={{ maxWidth: 300, minWidth: 100, maxHeight: PLOT_HEIGHT, overflow: "scroll", textAlign: "center" }}>
          {object_keys.map(key => renderListGroupItem(key, "x"))}
        </ListGroup>
      </div>
      <div style={{ flexDirection: "row", flex: 1, float: "left", marginLeft: 50 }}>
        <h3 style={{ textAlign: "center" }}>Y - Axis</h3>
        <ListGroup style={{ maxWidth: 300, minWidth: 100, maxHeight: PLOT_HEIGHT, overflow: "scroll", textAlign: "center" }}>
          {object_keys.map(key => renderListGroupItem(key, "y"))}
        </ListGroup>
      </div>
      <Plot

        data={[
          {
            x: xArr,
            y: yArr,
            type: 'scatter',
            mode: "markers",
            marker: { color: 'blue' },
          },
        ]}
        layout={{
          width: 1200, height: PLOT_HEIGHT, title: `${currentActiveX} vs ${currentActiveY}`,
          xaxis: { title: `${currentActiveX}` },
          yaxis: { title: `${currentActiveY}` }
        }}
        style={{ float: "right", flex: 1, marginTop: 40, marginRight: 20 }}

      />
    </div>
  )


}


export default PlottingPage;