/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate, useParams } from "react-router-dom";
import SnTable from "../components/sntable";
import FastAPIClient from "../utils/client";
import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar";
import TokenService from "../utils/token";
import useAuthStore from "../store/authStore";

const client = new FastAPIClient();

function BsmSNPage() {
  let params = useParams();
  const navigate = useNavigate();
  const { removeUser } = useAuthStore();
  const [isLoading, setLoading] = useState(true);
  const [dbData, setData] = useState([
    {
      bsmSN: "loading",
      ovmSN: "loading",
      sessionCount: "loading"
    }
  ]);
  const [pageTitle, setPageTitle] = useState("");
  const [tableData, setTableData] = useState("");
  const accessToken = TokenService.getToken();

  useEffect(() => {
    if (accessToken) {
      client
        .getMetadataByPk(params.metadataFK, accessToken)
        .then((data) => {
          if (data === 403) {
            removeUser();
            navigate("/login/tkc");
          }
          if (data === 404) {
            setTableData(404)
            setPageTitle("Data not found")
            return
          }
          setData(data);
          setPageTitle(`Viewing Page for OVM SN: ${data[0].ovmSN}`)
          const localToken = TokenService.getToken();
          client
            .getTelemetryDataByMetadataFk(params.metadataFK, localToken)
            .then((data) => {
              if (data === 403) {
                removeUser();
                navigate("/login/tkc");
              }
              setTableData(data);
              setLoading(false);
            });

        });

    }
  }, [params.metadataFK, accessToken]);

  // if (isLoading) {
  //   return (
  //     <div>
  //       <CustomNavbar name={"LOADING"} />
  //       <div>LOADING</div>
  //     </div>
  //   );
  // }

  return (
    <div className="h-full">
      <CustomNavbar name={pageTitle} />
      <div className="border-8 border-black h-full">
        <div style={{ height: "87dvh", overflow: "scroll" }}>
          {tableData === 404 ? <div className="justify-center items-center"> Data not found! </div> :
            <SnTable name={[params.metadataFK, dbData, accessToken, tableData, isLoading]} />
          }
        </div>
      </div>
    </div>
  );
};

export default BsmSNPage;
