import React, { useEffect, useState, useMemo } from "react";
import FastAPIClient from "../utils/client";
import TableContainer from "./tableContainer";
import { SchemaV0AdminColumns, SchemaV0Columns, SchemaV1Columns, SchemaV1AdminColumns } from "../utils/const";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSVLink } from "react-csv";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuthStore } from "../store/authStore";
import { Spinner, Placeholder } from "react-bootstrap";
import { varMapper } from "../utils/const";
const client = new FastAPIClient();

const PI_SIGNALS = ["inverterPiError",
  "inverterPiSetpoint",
  "inverterPiOutput",
  "pfcPiSetpoint",
  "pfcPiError",
  "pfcPiOutput",];

export function convertTimestamp(timestamp) {
  let myDate = new Date(timestamp);
  let localDate = myDate.toLocaleString('en-US', { hour12: false });
  return localDate;
}

function prepDataForCsv(dbData) {
  const returnObjArray = []
  for (const entry of dbData) {
    const objHolder = {};
    for (const key of Object.keys(entry)) {
      objHolder[varMapper[key]] = entry[key];
    }
    returnObjArray.push(objHolder);
  }
  return returnObjArray;
}

export default function SnTable(props) {
  const dbData = props.name[3];
  const formattedData = prepDataForCsv(dbData);

  const isLoading = props.name[4];
  const { userProfile, addUser } = useAuthStore();
  const passedInData = props.name[1];
  const bsmSN = passedInData[0].bsmSN;
  const ovmSN = passedInData[0].ovmSN;
  let dateString = new Date(passedInData[0].timestamp);
  let schemaVersion = passedInData[0].schemaVersion;
  let fileName = `${dateString.getFullYear()}_${dateString.getMonth() + 1}_${dateString.getDate()}_${dateString.getHours()}_${dateString.getMinutes()}_${dateString.getSeconds()}`
  fileName = fileName + `_TBS_${bsmSN}_OVR_${ovmSN}`

  const col = useMemo(
    () => {

      if (userProfile.isAdmin) {
        switch (schemaVersion) {
          case 1:
            return SchemaV1AdminColumns;
          default:
            return SchemaV0AdminColumns;
        }
      }
      else {
        switch (schemaVersion) {
          case 1:
            return SchemaV1Columns;
          default:
            return SchemaV0Columns;
        }
      }
    },

    [schemaVersion]
  );
  if (isLoading) {
    let placeholders = [];
    for (let i = 0; i < 25; i++) {
      placeholders.push((<Placeholder xs={12} />))
    }
    return (
      <div>
        <Placeholder as="p" animation="wave">
          {placeholders.map((placeholder) => { return placeholder })}
        </Placeholder>

      </div>
    );
  }
  return (
    <div className="">
      <div className="">

        <Button color="secondary" style={{ float: "right" }}>
          <CSVLink
            style={{ color: "#212226" }}
            data={formattedData}
            filename={fileName}
          >
            DOWNLOAD CSV
          </CSVLink>
        </Button>
        <TableContainer columns={col} data={dbData} enablePlotting={true} />
      </div>

      <Button color="secondary" style={{ float: "right" }}>
        <CSVLink
          style={{ color: "#212226" }}
          data={formattedData}
          filename={fileName}
        >
          DOWNLOAD CSV
        </CSVLink>
      </Button>
    </div>
  );
}
