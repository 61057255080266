import React, { useState } from "react"
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { BsmStates, bsmColors, customBsmMapping } from "./liveStatusComponent";
import { RefreshCcw } from "lucide-react";

const MIN_WIDTH = 0;

function reformatCountsToPercents(events, totalTime) {

    const eventIds = Object.keys(events);
    for (const id of eventIds) {
        events[id]["count"] = ((events[id]["count"] / totalTime) * 100) + MIN_WIDTH;
    }

}

function formatDateForTicker(ts) {

    const dateObject = new Date(ts);

    const dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`
    return dateString
}

function getTimeForTicker(ts) {

    const dateObject = new Date(ts);

    const hours = dateObject.getHours();
    let hoursString = hours.toString();
    let ampm = "am";
    if (hours === 0) {
        hoursString = "12";
    }
    else if (hours < 10) {
        hoursString = "0" + hoursString;
    } else if (hours > 12) {
        const hourDiff = hours - 12;
        hoursString = hourDiff.toString();
        ampm = "pm"
        if (hourDiff < 10) {
            hoursString = "0" + hoursString;
        }
    }

    const minutes = dateObject.getMinutes();
    let minutesString = minutes.toString();
    if (minutes < 10) {
        minutesString = "0" + minutesString;
    }

    return `${hoursString}:${minutesString} ${ampm.toLocaleUpperCase()}`
}

export function Timeline({ sn, stateHistory }) {

    const [render, setRender] = useState(false);
    const [customBsmTimes, setCustomBsmTimes] = useState({});

    const timelineData = stateHistory[sn];

    let timestamps = Object.keys(timelineData);
    let totalTime = timestamps.length;
    if (totalTime === 0) {
        return <>
        </>
    }
    let startingIndex = 0;
    let endingIndex = timestamps.length - 1;

    if (customBsmTimes.startTime && customBsmTimes.endTime) {
        startingIndex = timestamps.indexOf(customBsmTimes.startTime);
        endingIndex = timestamps.indexOf(customBsmTimes.endTime);

        totalTime = timestamps.slice(startingIndex, endingIndex + 1).length;
    }

    const minTime = Number(timestamps[startingIndex]);
    const maxTime = Number(timestamps[endingIndex]);

    // const localTimeDictTicks = Object.keys(localTimeDict);
    // let i = 1; 
    let currentEvent = timelineData[timestamps[startingIndex]]['bsmState'];
    const events = {
        0: {
            "bsmState": currentEvent,
            "count": 0,
            "time": timestamps[startingIndex],
        }
    };
    let event_num = 0;

    for (const ts of timestamps.slice(startingIndex)) {
        // if (ts <= localTimeDictTicks[i]){
        //     localTimeDict[localTimeDictTicks[i-1]][ts] = 1 
        // } else {
        //     localTimeDict[localTimeDictTicks[i]][ts] = 1 
        //     i += 1
        // }
        const tempEvent = timelineData[ts]['bsmState'];
        if (ts > timestamps[endingIndex]) {
            if (tempEvent === currentEvent) {
                events[event_num]['count'] += 1
                totalTime += 1
                continue
            } else {
                break
            }
        }

        if (tempEvent === currentEvent) {
            events[event_num]['count'] += 1
        } else {
            event_num += 1
            events[event_num] = {
                "bsmState": tempEvent,
                count: 1,
                time: ts
            }
            currentEvent = tempEvent;
        }
    }


    reformatCountsToPercents(events, totalTime);
    return (
        <>

            <text className="text-center text-xl font-black">
                Timeline for {sn}
                {(customBsmTimes.startTime && customBsmTimes.endTime) ? (
                    <Button className="ml-2" onClick={() => setCustomBsmTimes({})}>
                        <RefreshCcw />
                    </Button>) : <></>}
            </text >
            <div className="w-full h-full flex flex-col flex-nowrap border-2 overflow-x-auto py-10 px-2">

                <div className="w-full h-full items-center flex flex-row rounded py-1">
                    {
                        Object.keys(events).map((eventId) => {

                            const mappedState = customBsmMapping[events[eventId]['bsmState']] ? customBsmMapping[events[eventId]['bsmState']] : events[eventId]['bsmState']

                            return (<TimelineHeatMap
                                bsmState={mappedState}
                                time={events[eventId]['time']}
                                width={events[eventId]['count']}
                                sn={sn}
                                customBsmTimes={customBsmTimes}
                                setCustomBsmTimes={setCustomBsmTimes}
                                setRender={setRender} />)
                        })
                    }

                </div>

                <div className="flex flex-row items-center w-full rounded">
                    {/* {
                        Object.keys(events).map((eventId) => {
                            return (<TimelineElementTicker bsmState={events[eventId]['bsmState']} time={events[eventId]['time']} width={events[eventId]['count']} />)
                        })

                    } */}
                    <TimelineElementTicker minTime={minTime} maxTime={maxTime} events={events} />

                </div>


            </div>
        </>
    )
}


function TimelineHeatMap({ bsmState, width, time, sn, customBsmTimes, setCustomBsmTimes, setRenderCustom }) {
    const renderWidth = width < MIN_WIDTH ? MIN_WIDTH : width

    const date = new Date(+time);
    const backgroundColor = `#${bsmColors[bsmState]}`

    let crossed = "";
    if (BsmStates[bsmState].includes("Charging")) {
        crossed = "crossed";
    }

    const handleMouseDown = (time) => {
        customBsmTimes["startTime"] = time;
    }

    const handleMouseUp = (time) => {
        customBsmTimes["endTime"] = time;
        setCustomBsmTimes({ ...customBsmTimes });
    }

    return (
        <div className="flex flex-col gap-y-3" style={{
            width: `${renderWidth}%`, maxWidth: `${renderWidth}%`, minWidth: `${renderWidth}%`,
            height: "100%", borderColor: "grey", minHeight: "40px"
        }}>
            <div>
                <OverlayTrigger placement="top" overlay={
                    <Tooltip id={`${date.toLocaleDateString}${BsmStates[bsmState]}`} placement="top" className="flex flex-col">
                        <div>
                            {date.toLocaleString()}
                        </div>
                        <div>
                            {BsmStates[bsmState]}
                        </div>
                    </Tooltip>}>
                    <div id={`${sn}-${time}`} className={`rounded font-bold items-center ${crossed}`} style={{
                        width: `100%`, backgroundColor: backgroundColor, maxWidth: `100%`, minWidth: `${renderWidth}%`,
                        height: "150%", borderColor: "grey", minHeight: "40px"
                    }}
                        onMouseDown={(event) => { event.preventDefault(); handleMouseDown(time) }} onMouseUp={() => handleMouseUp(time)}
                    >


                        {/* <div className="flex flex-col text-wrap w-full justify-end text-center font-bold text-md">
                    <text>
                        {date.toLocaleString()}
                    </text>
                    <text>
                        {BsmStates[bsmState]}
                    </text>
                </div> */}


                    </div>
                </OverlayTrigger>
            </div>
            <div className='flex flex-col justify-start rounded' style={{ width: `100% `, maxWidth: `100%`, minWidth: `100%` }}>
                {/* <div className="text-sm font-bold w-full">
                    |
                </div>
                <div>

                </div>
                <div className="text-sm font-bold w-full">
                    {BsmStates[bsmState].replace("BSM_", "")}
                </div>
                <div className="text-sm font-bold w-full">
                    {date.toLocaleTimeString()}
                </div>
                <div className="text-sm font-bold w-full">
                    {date.toLocaleDateString()}
                </div> */}
            </div>


        </div>
    )
}


function TimelineElementTicker({ minTime, maxTime, events }) {


    const eventIds = Object.keys(events);

    const tempTimeTicks = [];
    var quarterTotal = 0;
    if (eventIds.length > 50) {
        tempTimeTicks.push(Number(events['0']['time']))
        for (const e in events) {
            const eventObj = events[e];

            quarterTotal += eventObj['count'];
            if (quarterTotal >= 25) {
                quarterTotal = 0
                tempTimeTicks.push(Number(eventObj['time']));
                continue
            }
        }
    }

    const diffTime = maxTime - minTime;

    const increments = (diffTime / 4).toFixed(0);
    let i = 0;
    var timeTicks = [];

    while (i < 4) {
        timeTicks.push(minTime + (increments * i));
        i += 1;
    }

    const renderWidth = (100 / timeTicks.length).toFixed(2);

    if (tempTimeTicks.length > 0) {
        timeTicks = tempTimeTicks;
    }

    return (
        <>
            {
                timeTicks.map((ts) => {
                    return (
                        <div className='flex flex-col justify-start rounded' style={{ width: `${renderWidth}% `, maxWidth: `${renderWidth}%`, minWidth: `${renderWidth}%` }}>
                            <div className="text-lg font-bold w-full">
                                |
                            </div>
                            <div>
                                {formatDateForTicker(ts)}
                            </div>
                            <div>
                                {getTimeForTicker(ts)}
                            </div>
                        </div>)
                })
            }
        </>

    )

}