import { convertTimestamp } from "../components/sntable"
/*
[
    {
        header: "log"

    }
]


main_cols = {
    "log": {  
        "date": {
                    accessor: "timestamp",
                    fnc?: ({row}) => convertTimestamp
                    adminOnly?: True
        }    
    },
}


*/

export const varMapper = {
    "timestamp": "Date",
    "ovmVoltage": "OVR Voltage",
    "ovmCurrent": "OVR Current",
    "ovmTemperature": "OVR Temperature",
    "ovmStatus": "OVR Status",
    "ovmError": "OVR Error",
    "ovmState": "OVR State",
    "fodStatus": "FOD Status",
    "foreignObject": "Foreign Object",
    "ovmDetected": "OVR Detected",
    "ovmAligned": "OVR Aligned",
    bsmAdc1: "Temp Sensor 1",
    bsmAdc2: "Temp Sensor 2",
    bsmAdc3: "Temp Sensor 3",
    bsmStatus: "TBS Status",
    bsmError: "TBS Error",
    bsmState: "TBS State",
    elapsedChargeTime: "Elapsed Charge Time",
    misalignment: "Misalignment",
    receivedCurrent: "BMS Received Current",
    requestedCurrent: "BMS Requested Current",
    batteryVoltage: "Battery Voltage",
    maxVoltage: "BMS Max Voltage",
    stateOfCharge: "State Of Charge",
    batteryTemperature: "Battery Temperature",
    batteryStatus: "Battery Status",
    inverterPiSetpoint: "Inverter Pi Setpoint",
    inverterPiError: "Inverter Pi Error",
    inverterPiOutput: "Inverter Pi Output",
    pfcPiSetpoint: "PFC Pi Setpoint",
    pfcPiError: "PFC Pi Error",
    pfcPiOutput: "PFC Pi Output",
    uOvmSerialNumber: "OVR Serial Number",
    bMoveLeft: "Move Left",
    bMoveRight: "Move Right",
    bMoveBack: "Move Back",
    bMoveIn: "Move In",
    fVerticalMisalignment: "Vertical Misalignment",
    fGap: "Gap",
    fHorizontalMisalignment: "Horizontal Misalignment",
    messageId: "Message ID",
    datalogBsmSN: "TBS SN",
    datalogOvmSN: "OVR SN"
}

export const SchemaV0Columns = [
    {
        Header: "log",
        columns: [
            {
                id: "date",
                Header: "Date",
                accessor: "timestamp",
                disableFilters: true,
                Cell: ({ row }) => convertTimestamp(row.original.timestamp),
            },
        ],
    },
    {
        Header: "OVR Data",
        columns: [
            {
                Header: "Voltage",
                accessor: "ovmVoltage",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmVoltage.toFixed(2) }
            },
            {
                Header: "Current",
                accessor: "ovmCurrent",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmCurrent.toFixed(2) }
            },
            {
                Header: "Status",
                accessor: "ovmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "ovmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "ovmState",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "FOD Data",
        columns: [
            {
                Header: "FOD Status",
                accessor: "fodStatus",
                disableFilters: true,
            },
            {
                Header: "Foreign Object",
                accessor: "foreignObject",
                disableFilters: true,
            },
            {
                Header: "OVR Detected",
                accessor: "ovmDetected",
                disableFilters: true,
            },
            {
                Header: "OVR Aligned",
                accessor: "ovmAligned",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "TBS Data",
        columns: [
            {
                Header: "Temp Sensor 1",
                accessor: "bsmAdc1",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc1.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 2",
                accessor: "bsmAdc2",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc2.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Status",
                accessor: "bsmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "bsmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "bsmState",
                disableFilters: true,
            },
        ],
    },
]

export const SchemaV0AdminColumns = [
    {
        Header: "log",
        columns: [
            {
                id: "date",
                Header: "Date",
                accessor: "timestamp",
                disableFilters: true,
                Cell: ({ row }) => convertTimestamp(row.original.timestamp),
            },
            {
                Header: "Elapsed Charge Time",
                accessor: "elapsedChargeTime",
                disableFilters: true,
            }
        ],
    },
    {
        Header: "OVR Data",
        columns: [
            {
                Header: "Voltage",
                accessor: "ovmVoltage",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmVoltage.toFixed(2) }
            },
            {
                Header: "Current",
                accessor: "ovmCurrent",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmCurrent.toFixed(2) }
            },
            {
                Header: "Temperature",
                accessor: "ovmTemperature",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmTemperature.toFixed(2) }
            },
            {
                Header: "Status",
                accessor: "ovmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "ovmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "ovmState",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "FOD Data",
        columns: [
            {
                Header: "Misalignment",
                accessor: "misalignment",
                disableFilters: true,
            },
            {
                Header: "FOD Status",
                accessor: "fodStatus",
                disableFilters: true,
            },
            {
                Header: "Foreign Object",
                accessor: "foreignObject",
                disableFilters: true,
            },
            {
                Header: "OVR Detected",
                accessor: "ovmDetected",
                disableFilters: true,
            },
            {
                Header: "OVR Aligned",
                accessor: "ovmAligned",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "BMS Data",
        columns: [
            {
                Header: "Received Current",
                accessor: "receivedCurrent",
                disableFilters: true,
            },
            {
                Header: "Requested Current",
                accessor: "requestedCurrent",
                disableFilters: true,
            },
            {
                Header: "Battery Voltage",
                accessor: "batteryVoltage",
                disableFilters: true,
            },
            {
                Header: "Max Voltage",
                accessor: "maxVoltage",
                disableFilters: true,
            },
            {
                Header: "State Of Charge",
                accessor: "stateOfCharge",
                disableFilters: true,
            },
            {
                Header: "Battery Temperature",
                accessor: "batteryTemperature",
                disableFilters: true,
            },
            {
                Header: "Battery Status",
                accessor: "batteryStatus",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "TBS Data",
        columns: [
            {
                Header: "Temp Sensor 1",
                accessor: "bsmAdc1",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc1.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 2",
                accessor: "bsmAdc2",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc2.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 3",
                accessor: "bsmAdc3",
                disableFilters: true,
                Cell: ({ row }) => row.original.bsmAdc3.toFixed(2) + " °C"
            },
            {
                Header: "Status",
                accessor: "bsmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "bsmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "bsmState",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "PI Data",
        columns: [
            {
                Header: "inverterPiSetpoint",
                accessor: "inverterPiSetpoint",
                disableFilters: true,
            },
            {
                Header: "inverterPiError",
                accessor: "inverterPiError",
                disableFilters: true,
            },
            {
                Header: "inverterPiOutput",
                accessor: "inverterPiOutput",
                disableFilters: true,
            },
            {
                Header: "pfcPiSetpoint",
                accessor: "pfcPiSetpoint",
                disableFilters: true,
            },
            {
                Header: "pfcPiError",
                accessor: "pfcPiError",
                disableFilters: true,
            },
            {
                Header: "pfcPiOutput",
                accessor: "pfcPiOutput",
                disableFilters: true,
            },
        ],
    },
]

export const SchemaV1Columns = [
    {
        Header: "log",
        columns: [
            {
                id: "date",
                Header: "Date",
                accessor: "timestamp",
                disableFilters: true,
                Cell: ({ row }) => convertTimestamp(row.original.timestamp),
            },
        ],
    },
    {
        Header: "OVR Data",
        columns: [
            {
                Header: "Voltage",
                accessor: "ovmVoltage",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmVoltage.toFixed(2) }
            },
            {
                Header: "Current",
                accessor: "ovmCurrent",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmCurrent.toFixed(2) }
            },
            {
                Header: "Status",
                accessor: "ovmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "ovmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "ovmState",
                disableFilters: true,
            },
            {
                Header: "Serial Number",
                accessor: "uOvmSerialNumber",
                disableFilters: true,
            }
        ],
    },
    {
        Header: "FOD Data",
        columns: [
            {
                Header: "FOD Status",
                accessor: "fodStatus",
                disableFilters: true,
            },
            {
                Header: "Foreign Object",
                accessor: "foreignObject",
                disableFilters: true,
            },
            {
                Header: "OVR Detected",
                accessor: "ovmDetected",
                disableFilters: true,
            },
            {
                Header: "OVR Aligned",
                accessor: "ovmAligned",
                disableFilters: true,
            },
            {
                Header: "Move Left",
                accessor: "bMoveLeft",
                disableFilters: true,
            },
            {
                Header: "Move Right",
                accessor: "bMoveRight",
                disableFilters: true,
            },
            {
                Header: "Move In",
                accessor: "bMoveIn",
                disableFilters: true,
            },
            {
                Header: "Move Back",
                accessor: "bMoveBack",
                disableFilters: true,
            },
            {
                Header: "Vertical Misalignment",
                accessor: "fVerticalMisalignment",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fVerticalMisalignment.toFixed(1) }
            },
            {
                Header: "Gap",
                accessor: "fGap",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fGap.toFixed(1) }
            },
            {
                Header: "Horizontal Misalignment",
                accessor: "fHorizontalMisalignment",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fHorizontalMisalignment.toFixed(1) }
            },
        ],
    },
    {
        Header: "TBS Data",
        columns: [
            {
                Header: "Temp Sensor 1",
                accessor: "bsmAdc1",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc1.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 2",
                accessor: "bsmAdc2",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc2.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Status",
                accessor: "bsmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "bsmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "bsmState",
                disableFilters: true,
            },
        ],
    },
]

export const SchemaV1AdminColumns = [
    {
        Header: "log",
        columns: [
            {
                id: "date",
                Header: "Date",
                accessor: "timestamp",
                disableFilters: true,
                Cell: ({ row }) => convertTimestamp(row.original.timestamp),
            },
            {
                Header: "Elapsed Charge Time",
                accessor: "elapsedChargeTime",
                disableFilters: true,
            }
        ],
    },
    {
        Header: "OVR Data",
        columns: [
            {
                Header: "Voltage",
                accessor: "ovmVoltage",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmVoltage.toFixed(2) }
            },
            {
                Header: "Current",
                accessor: "ovmCurrent",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmCurrent.toFixed(2) }
            },
            {
                Header: "Temperature",
                accessor: "ovmTemperature",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.ovmTemperature.toFixed(2) }
            },
            {
                Header: "Status",
                accessor: "ovmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "ovmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "ovmState",
                disableFilters: true,
            },
            {
                Header: "Serial Number",
                accessor: "uOvmSerialNumber",
                disableFilters: true,
            }
        ],
    },
    {
        Header: "FOD Data",
        columns: [
            {
                Header: "Foreign Object",
                accessor: "foreignObject",
                disableFilters: true,
            },
            {
                Header: "OVR Detected",
                accessor: "ovmDetected",
                disableFilters: true,
            },
            {
                Header: "OVR Aligned",
                accessor: "ovmAligned",
                disableFilters: true,
            },
            {
                Header: "Move Left",
                accessor: "bMoveLeft",
                disableFilters: true,
            },
            {
                Header: "Move Right",
                accessor: "bMoveRight",
                disableFilters: true,
            },
            {
                Header: "Move In",
                accessor: "bMoveIn",
                disableFilters: true,
            },
            {
                Header: "Move Back",
                accessor: "bMoveBack",
                disableFilters: true,
            },
            {
                Header: "Vertical Misalignment",
                accessor: "fVerticalMisalignment",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fVerticalMisalignment.toFixed(1) }
            },
            {
                Header: "Gap",
                accessor: "fGap",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fGap.toFixed(1) }
            },
            {
                Header: "Horizontal Misalignment",
                accessor: "fHorizontalMisalignment",
                disableFilters: true,
                Cell: ({ row }) => { return row.original.fHorizontalMisalignment.toFixed(1) }
            },
        ],
    },
    {
        Header: "BMS Data",
        columns: [
            {
                Header: "Received Current",
                accessor: "receivedCurrent",
                disableFilters: true,
            },
            {
                Header: "Requested Current",
                accessor: "requestedCurrent",
                disableFilters: true,
            },
            {
                Header: "Battery Voltage",
                accessor: "batteryVoltage",
                disableFilters: true,
            },
            {
                Header: "Max Voltage",
                accessor: "maxVoltage",
                disableFilters: true,
            },
            {
                Header: "State Of Charge",
                accessor: "stateOfCharge",
                disableFilters: true,
            },
            {
                Header: "Battery Temperature",
                accessor: "batteryTemperature",
                disableFilters: true,
            },
            {
                Header: "Battery Status",
                accessor: "batteryStatus",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "TBS Data",
        columns: [
            {
                Header: "Temp Sensor 1",
                accessor: "bsmAdc1",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc1.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 2",
                accessor: "bsmAdc2",
                disableFilters: true,
                Cell: ({ row }) => {
                    let showNum = row.original.bsmAdc2.toFixed(2)
                    if (showNum < 1) {
                        showNum = showNum * 100
                    }
                    return (showNum + " °C")
                }
            },
            {
                Header: "Temp Sensor 3",
                accessor: "bsmAdc3",
                disableFilters: true,
                Cell: ({ row }) => row.original.bsmAdc3.toFixed(2) + " °C"
            },
            {
                Header: "Status",
                accessor: "bsmStatus",
                disableFilters: true,
            },
            {
                Header: "Error",
                accessor: "bsmError",
                disableFilters: true,
            },
            {
                Header: "State",
                accessor: "bsmState",
                disableFilters: true,
            },
        ],
    },
    {
        Header: "PI Data",
        columns: [
            {
                Header: "Inverter Pi Setpoint",
                accessor: "inverterPiSetpoint",
                disableFilters: true,
            },
            {
                Header: "Inverter Pi Error",
                accessor: "inverterPiError",
                disableFilters: true,
            },
            {
                Header: "Inverter Pi Output",
                accessor: "inverterPiOutput",
                disableFilters: true,
            },
            {
                Header: "Pfc Pi Setpoint",
                accessor: "pfcPiSetpoint",
                disableFilters: true,
            },
            {
                Header: "Pfc Pi Error",
                accessor: "pfcPiError",
                disableFilters: true,
            },
            {
                Header: "pfcPiOutput",
                accessor: "pfcPiOutput",
                disableFilters: true,
            },
        ],
    },
]

// reference schedule_models.py
export const ChargeType = [
    [1, "Fast Charge"],
    [2, "Slow Charge"],
    [3, "Opportunity Charge"],
    [4, "Equalization Charge"],
    [0, "No Charge"],
]

export function getChargeTypeString(c) {
    for (const tup of ChargeType) {
        if (tup[0] === c) {
            return tup[1]
        }
    }
    return undefined
}

export const Weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];