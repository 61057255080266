import CustomNavbar from '../components/navbar';
import { Button, Image, Form, FloatingLabel, Card, Spinner, Alert, Fade } from "react-bootstrap";
import FastAPIClient from "../utils/client";
import { useAuthStore } from "../store/authStore";
import { useState } from 'react';

const client = new FastAPIClient();
const logo = require("../photos/Resonant-Link-Logomark_Black.png")

var sectionStyle = {
  backgroundImage: `url(${logo})`,
  width: '100%',
  height: '100vh'
}

export default function ContactUs() {
  const { userProfile, addUser } = useAuthStore();

  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [failureString, setFailureString] = useState("'")
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    const subject = e.target.subject.value
    const body = e.target.emailBody.value
    const resp = await client.postSendTicket(userProfile.tk, subject, body);

    if (resp.status_code !== 200) {
      setFailureString(resp.data.detail)
      setShowFailure(true)
      setTimeout(() => { setShowFailure(false) }, 10000)
    } else {
      setShowSuccess(true)
      setIsDisabled(true)
      setTimeout(() => { setShowSuccess(false); setIsDisabled(false) }, 10000)
    }
  }

  return (
    <div className='bg-black justify-center text-center items-center h-screen' style={sectionStyle}>
      <CustomNavbar />
      <div className='flex items-center justify-center p-2' style={{ color: '#FAFBFF' }}>
        <div className='w-1/2'>
          <Card className='bg-transparent'>
            <Card.Body>

              <h1 className='text-7xl'>
                Need Help? Contact Us!
              </h1>
              <h3>
                You can fill out the form below, or email us directly:
                <div className='font-bold'>support@resonant-link.com</div>
              </h3>
              {showFailure ? (<Alert variant="danger">{failureString}</Alert>) : (<></>)}
              {showSuccess ? (<Alert variant="success"> Email Sent! </Alert>) : (<></>)}
              <Form className='text-black mt-3' onSubmit={handleSubmit}>
                <FloatingLabel
                  label="Subject"
                  className="mb-3"
                  controlId='subject'
                >
                  <Form.Control type="text" />
                </FloatingLabel>

                <Form.Control id="emailBody" className="mt-3" as="textarea" rows={10} placeholder="Type your message here. If you are reporting a bug, please include steps to recreate" />
                <div className="text-center">
                  <Button variant="res"
                    type="submit"
                    className="mt-4 w-1/4 float-right"
                    disabled={isDisabled}
                  >
                    {"Send Email"}
                  </Button>
                </div>
              </Form>

            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )


}