import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Data from "./pages/data";
import Layout from "./components/layout";
import NotFoundPage from "./pages/notFoundPage";
import BsmSNPage from "./pages/bsmSN";
import UnauthPage from "./pages/unauthPage";
import TokenExpirePage from "./pages/tokenExpire";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ProtectedRoute } from "./components/ProtectedRoute";
import PlottingPage from "./pages/plotting";
import PlottingPageV2 from "./pages/plotting_v2";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import PasswordResetPage from "./pages/passwordReset";
import ContactUs from "./pages/contact";
import VerifyPage from "./pages/verify";
import CsPage from "./pages/csPage";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import SchedulerPage from "./pages/scheduler";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
  },
  {
    path: "/data",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/data/overview",
        element: <Data />,
      },
      {
        path: "/data/telemetry/:metadataFK",
        element: <BsmSNPage />,
      },
      {
        path: "/data/telemetry/:metadataFK/plotting/:templateId?",
        element: <PlottingPageV2 />
      },
      {
        path: "/data/telemetry/:metadataFK/plottingv2/:templateId?",
        element: <PlottingPage />
      }
    ]
  },
  {
    path: "/expired",
    element: <TokenExpirePage />
  },
  {
    path: "/unauthorized",
    element: <UnauthPage />,
  },
  {
    path: "/login/:loginType?",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />
  },
  {
    path: "/password_reset/:forgetToken?",
    element: <PasswordResetPage />
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/verify/:verifyToken?",
    element: <VerifyPage />
  },
  {
    path: '/data-dashboard',
    element: <CsPage />
  },
  {
    path: '/scheduler',
    element: <SchedulerPage />
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider
    clientId={`${process.env.REACT_APP_PUBLIC_GOOGLE_API_TOKEN}`}
  >
    <html data-theme="light" style={{ fontFamily: "Roboto" }} >
      <RouterProvider router={router} />
    </html>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
