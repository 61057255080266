import React, { useState } from "react";
import { checkValidTokenFromStore } from "../utils/tokenCheck";
import { useAuthStore } from "../store/authStore";
import { Button, Card, Carousel, Image, Form, InputGroup, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import FadeIn from 'react-fade-in';
import { useNavigate, useSearchParams } from "react-router-dom";


const pic = require("../photos/resLink.jpg");
const logo = require("../photos/Resonant-Link-Logomark_Black.png")
var sectionStyle = {
  backgroundImage: `url(${logo})`,
  width: '100%',
}
export default function LandingPage() {
  const [welcomeOption, setWelcomeOption] = useState(false);
  const navigate = useNavigate();
  const [searchParams, _fncs] = useSearchParams();
  const sourcePage = searchParams.get("source");
  const [showSource, setShowSource] = useState(sourcePage === "verify");
  const handleClose = () => setShowSource(false);
  const selectOptions = [
    {
      "value": "data",
      "label": "View All Data"
    },
    {
      "value": "dashboard",
      "label": "View Fleet Performance"
    }
  ]

  const handleSelectChange = (selectedOption) => {
    setWelcomeOption(selectedOption)
  }

  const handleWelcomeClick = () => {
    if (!welcomeOption) {
      console.log("Nothing to do")
      return
    }
    else {
      console.log(welcomeOption.value);
      if (welcomeOption.value === "data") {
        navigate("/data/overview")
      } else if (welcomeOption.value === "dashboard") {
        navigate("/data-dashboard")
      }
    }
  }

  //  <div style={{textAlign:"center", marginTop:10}}>
  return (
    <div className="h-full w-full">
      <style type="text/css">
        {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
      </style>
      <Offcanvas show={showSource} onHide={handleClose} placement={"top"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Account Verified!</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Your account has been verified and has been granted access the admin dashboard.
          If you have any questions or concerns, please submit an email using the "Contact Us" page.
        </Offcanvas.Body>
      </Offcanvas>
      <div className="flex flex-wrap h-1/2 w-full bg-black text-center items-center justify-center" style={sectionStyle}>
        <div className="w-2/5 h-full items-center justify-center ">
          <FadeIn>
            <h1 className="mt-24 gap-6 text-7xl w-full" style={{ color: "white", fontWeight: 'bold' }}>
              Welcome
            </h1>
          </FadeIn>
          <FadeIn delay={250}>
            <div className="w-full mt-5">
              <Select options={selectOptions} placeholder={"What do you want to do?"} onChange={handleSelectChange}></Select>
            </div>
          </FadeIn>
          <FadeIn delay={450}>
            <Button variant='res' disabled={!welcomeOption} className="w-1/4 mt-4" onClick={handleWelcomeClick}>
              Let's Go
            </Button>
          </FadeIn>
        </div>
      </div>
      <div className="h-1/2 w-full bg-black">
        <Carousel interval={5000} className="min-h-full">
          <Carousel.Item>
            <img
              className="block w-full h-full object-cover"
              src={pic}
            />
            <Carousel.Caption>
              <h3>Charge Fast</h3>
              <p>Get your data faster</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="min-h-full">
            <div className="h-full w-full block min-h-full text-center" style={{ color: "white" }}>
              <h1> Need help? </h1>
              <> contact us!</>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

    </div>

  );
}