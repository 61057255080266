import React from "react";

export const BsmStates = {
    0: "Startup",
    1: "Ready",
    2: "Alignment",
    3: "Preparing to Charge",
    4: "Object Detection", // cold start foreign object
    5: "Pairing",
    6: "Charging",
    7: "Battery Full",
    8: "Foreign Object",
    9: "Update",
    10: "Fault",
    11: "State End",
    255: "State Max",
}

const reverseMapping = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

export const reverseMappingBsmState = reverseMapping(BsmStates);


// this is used to capture one off mappings 
// ie: pairing and precharge should be just Preparing to Charge
export const customBsmMapping = {
    5: 3, // bsm state mapped to 3
    8: 4 // foreign object to object detgectiobn 
}


export const bsmColors = {
    0: "000000",
    1: "d3d3d3",
    2: "ffca00",
    3: "47C4FF",
    4: "EA8F28",
    5: "47C4FF",
    6: "c8dc2a",
    7: "c8dc2a",
    8: "EA8F28",
    9: "000000",
    10: "C62222",
    11: "000000",
    255: "000000",
}

export function LiveStatusComponent({ sn, latestDataMap }) {

    let status = "";
    let color = "#";
    let lastUpdatedString = ""
    let timeUnits = "";
    let crossed = "";

    const latestData = latestDataMap[sn];
    if (!latestData) {
        status = "No data recieved"
        color = "#c62222";
    } else {
        const mappedState = customBsmMapping[latestData['bsmState']] ? customBsmMapping[latestData['bsmState']] : latestData['bsmState']
        status = BsmStates[mappedState]
        if (mappedState === 6) {
            crossed = 'crossed';
        }
        color = color + bsmColors[mappedState]
        const nowTime = new Date();
        let timeDiff = nowTime.getTime() - latestData['timestamp'];
        timeDiff = timeDiff / (1000); // seconds
        timeDiff = timeDiff / 60; // minutes;

        // Handle nominal minutes case 
        if (timeDiff < 60) {
            timeDiff = Math.floor(timeDiff);

            if (timeDiff === 1) {
                timeUnits = "minute ago"
            } else {
                timeUnits = "minutes ago"
            }
            lastUpdatedString = `${timeDiff} ${timeUnits}`
        } else {
            // hours case

            timeDiff = timeDiff / 60; // hours 
            // nominal hours case, less than days
            if (timeDiff < 24) {
                timeDiff = Math.floor(timeDiff);
                if (timeDiff === 1) {
                    timeUnits = "hour ago"
                } else {
                    timeUnits = "hours ago"
                }
                lastUpdatedString = `${timeDiff} ${timeUnits}`
            } else {
                timeDiff = Math.floor(timeDiff / 24);
                if (timeDiff === 1) {
                    timeUnits = "day ago"
                } else {
                    timeUnits = "days ago"
                    //color = "#636363"
                }
                lastUpdatedString = `${timeDiff} ${timeUnits}`
            }
        }
    }

    return (
        <div className="items-center w-full flex flex-row flex-wrap gap-x-4 border-b py-2">
            <div className={`box-border h-4 w-4 ${crossed} rounded-md`} style={{
                backgroundColor: color,
            }}>
            </div>
            <div>
                charger #{sn} - <b>{status}</b>
            </div>
            <div className="w-full px-5  text-xs italic">
                Since: {lastUpdatedString}
            </div>
        </div>
    )

}