import React, { useState } from "react";
import { ChevronDown, ChevronUp, Pencil, XCircle, CheckCircle } from "lucide-react";
import { ListGroup, Button, Collapse, Dropdown, Modal, Form } from "react-bootstrap";
import { getChargeTypeString } from "../utils/const";
import { Weekdays } from "../utils/const";
import FastAPIClient from "../utils/client";

const client = new FastAPIClient();


export function CollapsableListGroupItem({ rule,
    setOpenModal,
    setSelectedRule,
    accessToken,
    setRefreshRules,
    setShowSuccess,
    setSuccessString }) {
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [activateDelete, setActivateDelete] = useState(false);
    const date = new Date(rule.modified_date ? rule.modified_date : rule.date_created);
    let fromWeekday = "";
    let toWeekday = "";
    let fromTimeString = "";
    let toTimeString = "";
    let isTimeWindow = false;
    let specialClass = "w-full h-full grid grid-cols-7 gap-7";
    // handle determination of charge execution
    let chargeExecutionType = "Default";
    if (rule.dayEnd) {
        chargeExecutionType = "Time Window";
        fromWeekday = Weekdays[rule.dayStart];
        const fromHour = rule.hourStart < 10 ? `0${rule.hourStart}` : `${rule.hourStart}`;
        const fromMinute = rule.minuteStart < 10 ? `0${rule.minuteStart}` : `${rule.minuteStart}`;
        toWeekday = Weekdays[rule.dayEnd];
        const toHour = rule.hourEnd < 10 ? `0${rule.hourEnd}` : `${rule.hourEnd}`;
        const toMinute = rule.minuteEnd < 10 ? `0${rule.minuteEnd}` : `${rule.minuteEnd}`;

        fromTimeString = fromHour + ":" + fromMinute;
        toTimeString = toHour + ":" + toMinute;
        isTimeWindow = true;

    } else if (rule.cadence) {
        chargeExecutionType = `Every ${rule.cadence} charges`;
    }


    const handleDelete = async () => {
        const resp = await client.deleteRuleById(rule.schedule_id, accessToken);
        if ((typeof resp === "number") && resp >= 400) {
            console.log("something went wrong!")
            return;
        }
        setOpen(false);
        setExpanded(false);
        setSuccessString("Successfully deleted rule!");
        setShowSuccess(true);
        setOpenDeleteModal(false);
        setActivateDelete(false);
        setRefreshRules(true);
    }

    return (
        <>
            <ListGroup.Item id={rule.schedule_id}>
                <div className="w-full h-full">
                    <div className="grid grid-cols-7 gap-4">
                        <div className="flex flex-col gap-2">
                            <div className="text-xs">
                                Charge Type
                            </div>
                            <div className="text-2xl">
                                {getChargeTypeString(rule.charge_type)}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-xs">
                                Charge Execution Type
                            </div>
                            <div className="text-2xl">
                                {chargeExecutionType}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-xs">
                                Applicable Chargers
                            </div>
                            <div className="text-xl">
                                {rule.chargers.join(', ')}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-xs">
                                Applicable Receivers
                            </div>
                            <div className="text-xl">
                                {rule.receivers.join(', ')}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 items-center justify-center text-center">
                            <div className="text-xs">
                                Acknowledged
                            </div>
                            <div className="text-xl text-center items-center justify-center">
                                {rule.appliedBy.length > 0 ? (<CheckCircle color="#15803d" />) : (<XCircle color="#dc2626" />)}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="text-xs">
                                Created/Modified Date
                            </div>
                            <div className="text-xl">
                                {date.toLocaleString()}
                            </div>
                        </div>
                        <div>
                            <div className="items-center justify-center py-2 text-center float-right">
                                <Button size='sm' variant="ghost" onClick={() => { setOpen(!open); setExpanded(!expanded) }}>
                                    {expanded ?
                                        <ChevronUp /> :
                                        <ChevronDown />
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Collapse in={open}>
                        <div className="border-t-2 w-full h-full flex flex-col bg-slate-100 p-2">
                            <div className="text-xs w-full border-b">
                                Additional Info and Actions
                            </div>
                            <div className={specialClass}  >
                                <div className="flex flex-col">
                                    <div className="text-base font-semibold">
                                        Created/Modified by
                                    </div>
                                    <div className="text-xl">
                                        {rule.created_by}
                                    </div>
                                </div>

                                {isTimeWindow ? (
                                    <div className="flex flex-col">
                                        <div className="text-base font-semibold">
                                            Time Window
                                        </div>
                                        <div className="flex flex-row w-full gap-x-4">
                                            <div className="flex flex-col">
                                                <div className="text-xs">
                                                    From
                                                </div>
                                                <div>
                                                    {fromWeekday}
                                                </div>
                                                <div>
                                                    {fromTimeString}
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="text-xs">
                                                    To
                                                </div>
                                                <div>
                                                    {toWeekday}
                                                </div>
                                                <div>
                                                    {toTimeString}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ) : (<div></div>)}

                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="font-semibold">
                                        Modified?
                                    </div>
                                    <div>
                                        {rule.modified ? "True" : "False"}
                                    </div>
                                </div>
                                <div>
                                    <div className="items-center justify-center py-2 text-center float-right gap-x-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="ghost" id="dropdown">
                                                <Pencil />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { setOpenModal(true); setSelectedRule(rule) }}>Modify</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => { setOpenDeleteModal(true) }}> Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </ListGroup.Item>

            <Modal show={openDeleteModal} onHide={() => { setOpenDeleteModal(false); setActivateDelete(false) }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title>
                        Deleting rule
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-full h-full gap-x-4 gap-y-10 flex flex-col">
                    <div className="text-3xl">
                        Are you sure you want to delete this rule?
                    </div>
                    <div className="flex flex-row">
                        <Form.Check
                            label={"I understand that this action cannot be undone"}
                            onClick={() => setActivateDelete(!activateDelete)}
                        />
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setOpenDeleteModal(false); setActivateDelete(false) }}>
                        Cancel
                    </Button>
                    <Button disabled={!activateDelete} onClick={() => handleDelete()}>
                        Yes
                    </Button>
                </Modal.Footer>

            </Modal>



        </>
    )

}