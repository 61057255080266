import { InputGroup, FloatingLabel, Form, Button } from "react-bootstrap"
import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

export default function PasswordWithVisibleToggle(feedback = "") {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    return (
        <>
            <style type="text/css">
                {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
            </style>
            <InputGroup>
                <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control type={showPassword ? "input" : "password"} placeholder="Password" />
                </FloatingLabel>
                <Button variant="res" onClick={handleShowPassword}>
                    {!showPassword ?
                        (<Eye />) : <EyeOff />}
                </Button>
            </InputGroup>
        </>

    )
}