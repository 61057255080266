import React from "react";

import { Link } from "react-router-dom";

import { useAuthStore } from "../store/authStore";
export default function TokenExpirePage() {
  const { removeUser } = useAuthStore();

  return (
    <h1 style={{ color: "#212226" }}>
      {" "}
      <Link style={{ color: "#212226" }} to={{ pathname: "/" }} onClick={removeUser}>
        Access Token Has Expired! Click here to return to Home!{" "}
      </Link>{" "}
    </h1>
  );
}
