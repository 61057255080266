import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import jwt_decode from "jwt-decode";



export const ProtectedRoute = ({ children }) => {

    const { userProfile } = useAuthStore();

    if (!userProfile){
        return <Navigate to="/unauthorized" />;
    }
    // TODO make more descriptive pages for separate errors.
    // IE: invalid <---> need to relog 
    // tk == null <---> need to log in 
    if (userProfile.tk === "invalid" || !userProfile.tk){
        return <Navigate to="/unauthorized" />;
    }

    if (userProfile.tk){
        // Check for expired token 
        const refresh_tk = userProfile.tk;
        const decoded_token = jwt_decode(refresh_tk);
        const now_time = Date.now();

        if (decoded_token.exp < Math.floor(now_time/1000)){
            return <Navigate to="/expired" />;
        }
    }
    return <Outlet />
}
