import React, { useState, useContext } from "react";
import { Button, Image, Form, FloatingLabel, Card, Spinner, Alert, Fade, InputGroup } from "react-bootstrap";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { useAuthStore } from "../store/authStore";
import { Eye, EyeOff } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import FadeIn from 'react-fade-in';
import FastAPIClient from "../utils/client";
import jwt_decode from "jwt-decode";
import PasswordWithVisibleToggle from "../components/passwordWithVisible";
const client = new FastAPIClient();
const logo = require("../photos/Resonant-Link-Logo_White.png")
export default function LoginPage(props) {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const { userProfile, addUser, removeUser } = useAuthStore();
    const [loginFailed, setLoginFailed] = useState(false);
    const [loginFailedString, setLogInFailedString] = useState("");
    const [showPasswordChanged, setShowPasswordChanged] = useState(params.loginType === "pw");
    const [showAccountCreated, setShowAccountCreated] = useState(params.loginType === "ac");
    const [showTokenExpired, setShowTokenExpired] = useState(params.loginType === "exp");
    const [showTokenCompromised, setShowTokenCompromised] = useState(params.loginType === "tkc");

    const navigate = useNavigate();

    const handleGoogleSignin = async (response) => {
        const decodedReponse = jwt_decode(response.credential);

        var userObject = {
            email: decodedReponse.email,
            sub: decodedReponse.sub,
            name: decodedReponse.name,
        }

        const userResponse = await client.postUserInfo(userObject);
        userObject = {};

        if (userResponse.status_code !== 200) {
            userObject["tk"] = null;
            setLogInFailedString(userResponse.data.detail)
            setLoginFailed(true)
            setTimeout(() => { setLoginFailed(false) }, 10000)
        } else {
            userObject["tk"] = userResponse.refresh_token;
            userObject["isAdmin"] = userResponse.isAdmin;
            userObject['verified'] = userResponse.verified;
            userObject["aT"] = userResponse.access_token;
            addUser(userObject)
            navigate("/")
        }
    }
    const onFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const email = (e.target.floatingInput.value);
        const password = (e.target.floatingPassword.value);

        const userObject = {
            email: email,
            password: password,
        }
        const userResponse = await client.postUserInfo(userObject)
        if (userResponse.status_code !== 200) {
            // means we failed 
            // TODO: Add fail log in message
            setLogInFailedString(userResponse.data.detail)
            addUser({ tk: null })
            setLoginFailed(true)
            setTimeout(() => { setLoginFailed(false) }, 10000)
        } else {
            // you can store refresh token in store
            // TODO: maybe push this into httpOnly? 
            const user_obj = {
                tk: userResponse.refresh_token,
                isAdmin: userResponse.isAdmin,
                verified: userResponse.verified,
                aT: userResponse.access_token
            }
            addUser(user_obj)
            navigate("/")
        }
        setLoading(false)
    }
    const handleForgotPassword = () => {
        navigate("/password_reset")
    }
    setTimeout(() => { setShowPasswordChanged(false) }, 3000)
    setTimeout(() => { setShowAccountCreated(false) }, 3000)
    setTimeout(() => { setShowTokenExpired(false) }, 3000)
    setTimeout(() => { setShowTokenCompromised(false) }, 3000)
    return (
        <div className="flex h-screen items-center w-full">
            <style type="text/css">
                {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
            </style>
            <div className="w-1/2 h-full bg-resBlack items-center justify-center rounded-r hidden md:flex">
                <div className="flex h-full w-full justify-center items-center">
                    <div className="w-1/2">
                        <li><a href='/'>
                            <Image src={logo} fluid />
                        </a>
                        </li>
                    </div>
                </div>
            </div>
            <div className="w-full bg-resWhite h-full items-ceneter justify-center rounded-l-lg md:w-1/2">
                <div className="flex h-full w-full justify-center items-center">
                    <div className="w-1/2">
                        <FadeIn>
                            {loginFailed ? (<Fade in={loginFailed}><Alert variant="danger">{loginFailedString}</Alert></Fade>) : (<></>)}
                            {showPasswordChanged ? (<Alert variant="success"> Password Successfully Changed! </Alert>) : (<></>)}
                            {showAccountCreated ? (<Alert variant="success"> Account Successfully Created! </Alert>) : (<></>)}
                            {showTokenExpired ? (<Alert variant="danger"> Session expired, please log in again to continue your session! </Alert>) : (<></>)}
                            {showTokenCompromised ? (<Alert variant="danger"> Oops! Something went wrong. Please reauthenticate to continue </Alert>) : (<></>)}
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center"> Log into Resonant Link Admin Dashboard</Card.Title>
                                    <Form onSubmit={onFormSubmit}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email Address"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </FloatingLabel>
                                        <PasswordWithVisibleToggle />
                                        <div className="text-center">
                                            <Button variant="link" className="float-left mt-1"
                                                onClick={handleForgotPassword}
                                            >Forgot password?</Button>
                                            <Button variant="res"
                                                type="submit"
                                                className="mt-2 w-1/4 float-right"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <Spinner animation="border" /> : "Log In"}
                                            </Button>
                                        </div>
                                    </Form>

                                </Card.Body>
                                <div className="relative flex justify-center text-xs uppercase mb-2">
                                    <span className="bg-background px-2 text-muted-foreground">
                                        Or continue with
                                    </span>
                                </div>
                                <div className="relative flex justify-center text-xs uppercase mb-2">
                                    <span className="bg-background px-2 text-muted-foreground flex">
                                        <GoogleLogin
                                            size={"medium"}
                                            className='flex'
                                            onSuccess={(response) => handleGoogleSignin(response)}
                                            onError={() => console.log("UNAUTHORIZED")}
                                        />
                                    </span>
                                </div>
                                <Card.Body>
                                    <div className="text-center">
                                        Dont have an account?
                                    </div>
                                    <div className="text-center">
                                        <Button variant='res'
                                            className='w-3/4 mb-2 mt-2'
                                            disabled={isLoading}
                                            onClick={() => navigate("/signup")}>
                                            Sign Up
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </FadeIn>

                    </div>
                </div>
            </div>
        </div>
    )
        ;
}
