import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown, Offcanvas, Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { Button } from "react-bootstrap";
import { useAuthStore } from "../store/authStore";
import { Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "../styles.css";

const logo = require("../photos/Resonant-Link-Logo_White.png");

const REFERENCE_URL = 'https://harvest-heat-163.notion.site/Link-Portal-Reference-Documentation-3d456b1cd3f845e19c0c6a9623579610';

function CustomNavbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const navLinkStyle = {
    fontWeight: "bold",
    fontSize: "20px",
  };

  const { userProfile, addUser, removeUser } = useAuthStore();
  useEffect(() => {
    if (!userProfile.tk) {
      removeUser();
      navigate("/login/exp");
    } else if (userProfile.tk === "invalid") {
      removeUser();
      navigate("/login/tkc");
    } else if (!userProfile.verified) {
      navigate("/verify");
    }
    const decoded = jwt_decode(userProfile.tk);
    if (Date.now() >= decoded.exp * 1000) {
      removeUser();
      navigate("/login/exp");
    }
  }, [])

  return (
    <div>
      <style type="text/css">
        {`
    .btn-res {
      background-color: #C7DE30;
      color: #212226; 
    }
    .btn-res:hover {
      background-color: #FAFBFF;
      transition: 0.7s;
    }
    .navbar-toggle-icon {
      background-color: #C7DE30;
    }
    `}
      </style>
      <Navbar className="bg-resBlack m-auto" data-bs-theme="dark" expand={false}>
        <Navbar.Brand href="/">
          <img
            alt="Resonant-Link-Logo_White"
            src={logo}
            style={{
              height: 60,
              width: 210,
              marginLeft: '20px'
            }}
          />
        </Navbar.Brand>
        <Nav fluid style={{ flex: 1 }}>
          <Navbar.Text style={{ marginLeft: "100px", color: "#FAFBFF", fontSize: "30px", fontWeight: "bold", width: "150%", textAlign: "center" }}>
            {props.name}
          </Navbar.Text>
        </Nav>
        {(userProfile && userProfile.tk) ? (
          (userProfile.tk === "invalid") ? (
            <Navigate to="/unauthorized" replace={true} />
          ) : (
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <div>
                {[false].map((expand) => (
                  <Container fluid>
                    <Navbar.Toggle className="bg-white" aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                      className="bg-black text-white"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                          Menu
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="me-auto" navbar>
                          {/* <NavbarText> Welcome {userProfile.name}!</NavbarText> */}
                          <NavDropdown.Item>
                            <Nav.Link as={NavLink} to="/data/overview" style={navLinkStyle}>
                              All Data
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Nav.Link as={NavLink} to="/data-dashboard" style={navLinkStyle}>
                              Fleet Performance
                            </Nav.Link>
                          </NavDropdown.Item>

                          {userProfile.isAdmin ? (
                            <NavDropdown.Item>
                              <Nav.Link as={NavLink} to="/scheduler" style={navLinkStyle}>
                                Manage Fleet Schedule
                              </Nav.Link>
                            </NavDropdown.Item>
                          ) : (<></>)}

                          <NavDropdown.Item>
                            <Nav.Link as={NavLink} to={REFERENCE_URL} style={navLinkStyle} onClick={() => window.location.replace(REFERENCE_URL)}>
                              Reference
                            </Nav.Link>
                          </NavDropdown.Item>

                          <NavDropdown.Item>
                            <Nav.Link as={NavLink} to="/contact" style={navLinkStyle}>
                              Contact Us
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Nav.Link
                              href="/"
                              style={navLinkStyle}
                              onClick={() => {
                                navigate("/");
                                googleLogout();
                                removeUser();
                              }}
                            >
                              Logout
                            </Nav.Link>
                          </NavDropdown.Item>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                ))}
              </div>
            </Nav>
          )) : (
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <div>
              <Button variant='res'
                size='lg'
                style={{ fontWeight: "bold" }}
                onClick={() => navigate("/login")}>
                Log In
              </Button>
            </div>
          </Nav>
        )}
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
