import Plot from 'react-plotly.js';
import React, { useEffect, useState } from "react";
import { ButtonGroup, Button, ListGroup, ListGroupItem, Navbar, Container } from "reactstrap";
import { useResizeDetector } from 'react-resize-detector'
import { Card } from 'react-bootstrap';
import _ from "lodash";
import { varMapper } from '../utils/const';

const PlotObj = (props) => {
  const { xName, yName, l_id, layouts, setLayouts, forceUpdate, plotData } = props
  const { width, height, ref } = useResizeDetector({
  })

  const yTitle = [];
  for (const yT of yName) {
    yTitle.push(varMapper[yT]);
  }

  const xTitle = varMapper[xName] === "Date" ? "timestamp" : varMapper[xName];

  const handleDelete = () => {
    for (const size_key in layouts) {
      layouts[size_key] = _.reject(layouts[size_key], { i: l_id });

    }
    setLayouts(layouts);
    forceUpdate();
  }
  return (<div ref={ref} style={{
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    border: '2px solid',
  }}>

    <div className="plot_header rounded-t">

      <ButtonGroup
        size="sm"
      >
        {/* <Button color="info">
                  Left
                </Button>
                <Button color="info">
                  Middle
                </Button> */}
        <Button color="danger" onClick={handleDelete}>
          Delete
        </Button>
      </ButtonGroup> </div>
    <div className='rounded-b'>
      <Plot className='plotter'
        useResizeHandler={true}
        data={plotData}
        layout={{
          title: `${xTitle} vs ${yTitle}`,
          xaxis: { title: `${xTitle}` },

          ...(plotData.length <= 1 && { yaxis: { title: `${yTitle}` } }),
          ...(plotData.length > 1 && {
            yaxis: { title: `${yTitle[0]}` },
            yaxis2: {
              title: `${yTitle[1]}`,
              overlaying: 'y',
              side: 'right'
            },
          }),
          width: width,
          height: height - 45,
          autosize: true,
          plot_bgcolor: 'rgba(250, 249, 246, 1)',
          paper_bgcolor: 'rgba(0, 0, 0, 0)'
        }
        }
        style={{ height: 'auto', width: 'auto' }}
      />
    </div>
  </div>)
}

export default PlotObj;