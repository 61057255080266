import React, { useEffect, useState, useMemo } from "react";
import FastAPIClient from "../utils/client";
import TableContainer from "./tableContainer";
import { SchemaV0AdminColumns, SchemaV0Columns, SchemaV1Columns, SchemaV1AdminColumns } from "../utils/const";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSVLink } from "react-csv";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuthStore } from "../store/authStore";
import { Spinner, Placeholder } from "react-bootstrap";
import { varMapper } from "../utils/const";
const client = new FastAPIClient();

const PI_SIGNALS = ["inverterPiError",
    "inverterPiSetpoint",
    "inverterPiOutput",
    "pfcPiSetpoint",
    "pfcPiError",
    "pfcPiOutput",];

export function convertTimestamp(timestamp) {
    let myDate = new Date(timestamp);
    let localDate = myDate.toLocaleString('en-US', { hour12: false });
    return localDate;
}

function prepDataForCsv(dbData) {
    const returnObjArray = []
    for (const entry of dbData) {
        const objHolder = {};
        for (const key of Object.keys(entry)) {
            objHolder[varMapper[key]] = entry[key];
        }
        returnObjArray.push(objHolder);
    }
    return returnObjArray;
}

export default function TableView(props) {

    const { userProfile, addUser } = useAuthStore();
    const schemaVersion = props.schemaVersion;



    const col = useMemo(
        () => {

            if (userProfile.isAdmin) {
                switch (schemaVersion) {
                    case 1:
                        return SchemaV1AdminColumns;
                    default:
                        return SchemaV0AdminColumns;
                }
            }
            else {
                switch (schemaVersion) {
                    case 1:
                        return SchemaV1Columns;
                    default:
                        return SchemaV0Columns;
                }
            }
        },

        [schemaVersion]
    );

    return (
        <div className="">
            <div className="">
                <TableContainer columns={col} data={props.dbData} enablePlotting={false} />
            </div>
        </div>
    );
}
