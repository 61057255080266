import React, { useState } from "react";
import { Button, Image, Form, FloatingLabel, Card, Spinner, Alert, InputGroup } from "react-bootstrap";
import { Eye, EyeOff } from "lucide-react";
import FadeIn from 'react-fade-in';
import FastAPIClient from "../utils/client";
import { useNavigate } from "react-router-dom";

// TODO:
// ADD SIGN UP FAILURE MSG 
// ADD SIGN UP SUCCESS MSG UPON REDIRECT

const logo = require("../photos/Resonant-Link-Logo_White.png")
const client = new FastAPIClient();

function containsAnyLetters(str) {
    return /[a-z]/.test(str)
}

function containsUpperCase(str) {
    return /[A-Z]/.test(str);
}
function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
}
export function validatePasswords(password, confirmPassword) {

    if (password !== confirmPassword) {
        return [true, "Passwords must match"]
    }
    if (password.length < 8) {
        return [true, "Passwords must be at least 8 characters!"]
    }
    if (!containsAnyLetters(password)) {
        return [true, "Passwords must contain letters"]
    }

    if (!containsUpperCase(password) || !containsSpecialChars(password)) {
        return [true, "Passwords must contain one uppercase letter and special character (e.g ! @ $)"]
    }
    return [false, ""]

}

export default function SignupPage() {
    const [isLoading, setLoading] = useState(false);
    const [isPasswordInvalid, setisPasswordInvalid] = useState(false);
    const [pwErrorStr, setPwErrorStr] = useState("");
    const sleep = ms => new Promise(r => setTimeout(r, ms))
    const [validated, setValidated] = useState(false);
    const [firstNameInvalid, setFirstNameInvalid] = useState(false);
    const [lastNameInvalid, setLastNameInvalid] = useState(false);
    const [showSignupAlert, setShowSignupAlert] = useState(false);
    const [signupAlertMsg, setSignupAlertMsg] = useState("");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const onFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const email = (e.target.floatingInput.value);
        const password = (e.target.floatingPassword.value);
        const confirmPassword = (e.target.floatingPasswordConfirm.value);
        const firstName = (e.target.firstName.value);
        const lastName = (e.target.lastName.value);
        if (!firstName) {
            setFirstNameInvalid(true);
        }
        if (!lastName) {
            setLastNameInvalid(true);
        }
        // password validation

        const [result, resString] = validatePasswords(password, confirmPassword)
        if (result) {
            setPwErrorStr(resString)
            setisPasswordInvalid(result)
            setLoading(false)
            setTimeout(() => { setisPasswordInvalid(false) }, 4000)
            return
        }

        // form the user obj to upload to client

        const userObject = {
            email: email,
            password: password,
            name: `${firstName} ${lastName}`
        }
        const userResponse = await client.postSignupUser(userObject)
        if (userResponse.status_code === 200) {
            navigate("/login/ac")
        } else {
            setShowSignupAlert(true)
            setSignupAlertMsg(userResponse.data.detail)
            setTimeout(() => { setShowSignupAlert(false) }, 3000)
        }
        setLoading(false)
    }

    const handleChange = () => {
        setisPasswordInvalid(false);
    }
    const handleFirstNameChange = () => {
        setFirstNameInvalid(false);
    }
    const handleLastNameChange = () => {
        setLastNameInvalid(false);
    }
    return (
        <div className="flex h-screen items-center w-full">
            <style type="text/css">
                {`
            .btn-res {
            background-color: #C7DE30;
            color: #212226;
            
            }
            .btn-res:hover {
            background-color: #FAFBFF;
            transition: 0.7s;
            }
        `}
            </style>
            <div className="w-1/2 h-full bg-resBlack items-center justify-center rounded-r hidden md:flex">
                <div className="flex h-full w-full justify-center items-center">
                    <div className="w-1/2">
                        <li><a href='/'>
                            <Image src={logo} fluid />
                        </a>
                        </li>
                    </div>
                </div>
            </div>
            <div className="w-1/2 h-full items-ceneter justify-center rounded-l-lg">
                <div className="flex h-full w-full justify-center items-center">
                    <div className="w-1/2">
                        <FadeIn>
                            {showSignupAlert ? (<Alert variant="danger"> {signupAlertMsg} </Alert>) : <></>}
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center mb-3"> Creating Account for Dashboard </Card.Title>
                                    <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                        <div className="inline-flex gap-6">
                                            <FloatingLabel
                                                controlId="firstName"
                                                label="First Name"
                                                className="mb-3 w-1/2"

                                            >
                                                <Form.Control isInvalid={firstNameInvalid} required placeholder="name@example.com" onChange={handleFirstNameChange} />
                                                <Form.Control.Feedback type="invalid">
                                                    Required
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="lastName"
                                                label="Last Name"
                                                className="mb-3 w-1/2"
                                            >
                                                <Form.Control isInvalid={lastNameInvalid} required placeholder="name@example.com" onChange={handleLastNameChange} />
                                                <Form.Control.Feedback type="invalid">
                                                    Required
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email address"
                                            className="mb-3"
                                        >
                                            <Form.Control required type="email" placeholder="name@example.com" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid email address
                                            </Form.Control.Feedback>

                                        </FloatingLabel>
                                        <InputGroup className="mb-3">
                                            <FloatingLabel controlId="floatingPassword" label="Password">
                                                <Form.Control isInvalid={isPasswordInvalid} required type={showPassword ? "input" : "password"} placeholder="Password" onChange={handleChange} />
                                                <Form.Control.Feedback type="invalid">
                                                    {pwErrorStr}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            {isPasswordInvalid ? <></> :
                                                <Button variant="res" onClick={handleShowPassword}>
                                                    {!showPassword ?
                                                        (<Eye />) : <EyeOff />}
                                                </Button>}
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <FloatingLabel controlId="floatingPasswordConfirm" label="Confirm Password">
                                                <Form.Control isInvalid={isPasswordInvalid} required type={showPassword ? "input" : "password"} placeholder="Password" onChange={handleChange} />
                                                <Form.Control.Feedback type="invalid">
                                                    {pwErrorStr}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            {isPasswordInvalid ? <></> :
                                                <Button variant="res" onClick={handleShowPassword}>
                                                    {!showPassword ?
                                                        (<Eye />) : <EyeOff />}
                                                </Button>}
                                        </InputGroup>

                                        <div className="text-center">
                                            <Button variant="res"
                                                type="submit"
                                                className="mt-2 w-3/4"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <Spinner animation="border" /> : "Register"}
                                            </Button>

                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </FadeIn>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}
