import React, { useEffect, useState, useMemo } from "react";
import TableContainer from "./tableContainer";
import FastAPIClient from "../utils/client";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TokenService from "../utils/token";
const client = new FastAPIClient();

const newDate = new Date()
const dateOffset = (24 * 60 * 60 * 1000) * 90;
newDate.setTime(newDate.getTime() - dateOffset);

export default function DataTable() {
  const [serialNumbers, setSN] = useState([]);
  const { userProfile, addUser, removeUser } = useAuthStore();
  const [accessToken, setAccessToken] = useState(TokenService.getToken());
  const [tableData, setTableData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [dateMap, setDateMap] = useState({});
  const [allData, setAllData] = useState({});
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      client.getAllData(accessToken).then((data) => {
        if (data === 403) {
          removeUser();
          navigate("/login/tkc");
        }
        setSN(data);
        const tempTableData = [];
        let dataDict = {};
        let eventsList = [];
        let dateCounter = {};
        let tempAllData = {};
        for (const d of data) {
          let myDate = new Date(d.timestamp);
          let localDate = myDate.toLocaleString('en-US', { hour12: false });
          let shortDate = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
          if (!(shortDate in dataDict)) {
            dataDict[shortDate] = [];
            dateCounter[shortDate] = { "count": 0, "shortDate": shortDate };
          }
          dataDict[shortDate].push(d.uniqueId);
          dateCounter[shortDate]["count"] = dateCounter[shortDate]["count"] + 1;
          let href_string = "/data/telemetry/" + d.uniqueId + "/plotting?templateId=0";
          /*
          maybe best way to represnt data is: 
          {
            uniqueId: {
              bsmsn_4: {}
            },
            uniqueId: {
              bsmsn_5: {}
            }
            uniqueId: {
              bsmsn_4: {}
            }
          }
          then upon event click do: 
          for (const u_id of clicked_event){
            
          }
          <div> Serial Number ${}</div>
          thisObj[unique_id]
          */
          const tempData = {
            bsmSN: d.bsmSN,
            ovmSN: d.ovmSN,
            sessionCount: d.sessionCount,
            bsmControlsVersion: d.bsmControlsVersion,
            ovmControlsVersion: d.ovmControlsVersion,
            pfcSN: d.pfcSN,
            fodSN: d.fodSN,
            bsmRfVersion: d.bsmRfVersion,
            ovmRfVersion: d.ovmRfVersion,
            pfcControlsVersion: d.pfcControlsVersion,
            fodControlsVersion: d.fodControlsVersion,
            calculatedChargeTime: d.calculatedChargeTime,
            timestamp: localDate,
            href_string: href_string,
            date_raw: myDate,
          };
          tempTableData.push(tempData);
          tempAllData[d.uniqueId] = tempData
        }

        for (const dateKey in dateCounter) {
          eventsList.push({
            title: `${dateCounter[dateKey]["count"]} sessions`,
            start: dateCounter[dateKey]["shortDate"],
            allDay: true,
            backgroundColor: "#C7DE30",
            borderColor: "#212226",
            textColor: "#212226",
            classNames: ['text-[11.5px]']
          })
        }

        setTableData(tempTableData);
        setEventData(eventsList);
        setDateMap(dataDict);
        setAllData(tempAllData);
      });
    }
  }, [accessToken])
  const columns = useMemo(
    () => {

      const returnObj = [
        {
          Header: "Date",
          accessor: "timestamp",
          Cell: ({ row }) => (
            <Link
              Link
              style={{ color: "#212226" }}
              to={{ pathname: `${row.original.href_string}` }}
            >
              {row.original.timestamp}
            </Link>
          ),
        },
        {
          Header: "Session Charge Time (minutes)",
          accessor: "calculatedChargeTime",
          Cell: ({ row }) => {
            const minutes = (row.original.calculatedChargeTime / 60);
            return (<Link
              Link
              style={{ color: "#212226" }}
              to={{ pathname: `${row.original.href_string}` }}
            >
              {minutes.toFixed(0)}
            </Link>)
          },
        },
        // {
        //   Header: "TBS Serial Number",
        //   accessor: "bsmSN",
        //   Cell: ({ row }) => (
        //     <Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.bsmSN}
        //     </Link>
        //   ),
        // },
        {
          Header: "OVR Serial Number",
          accessor: "ovmSN",
          Cell: ({ row }) => (
            <Link
              Link
              style={{ color: "#212226" }}
              to={{ pathname: `${row.original.href_string}` }}
            >
              {row.original.ovmSN}
            </Link>
          ),
        },
        // {
        //   Header: "PFC Serial Number",
        //   accessor: "pfcSN",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.pfcSN}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "FOD Serial Number",
        //   accessor: "fodSN",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.fodSN}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "Session Number",
        //   accessor: "sessionCount",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.sessionCount}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "TBS Controls Version",
        //   accessor: "bsmControlsVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.bsmControlsVersion}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "BSM RF Version",
        //   accessor: "bsmRfVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.bsmRfVersion}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "OVR Controls Version",
        //   accessor: "ovmControlsVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.ovmControlsVersion}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "OVM RF Version",
        //   accessor: "ovmRfVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.ovmRfVersion}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "PFC Controls Version",
        //   accessor: "pfcControlsVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.pfcControlsVersion}
        //     </Link>
        //   ),
        // },
        // {
        //   Header: "FOD Controls Version",
        //   accessor: "fodControlsVersion",
        //   Cell: ({ row }) => (
        //     <Link
        //       Link
        //       style={{ color: "#212226" }}
        //       to={{ pathname: `${row.original.href_string}` }}
        //     >
        //       {row.original.fodControlsVersion}
        //     </Link>
        //   ),
        // },
      ]

      if (userProfile.isAdmin) {
        returnObj.push(
          {
            Header: "TBS Controls Version",
            accessor: "bsmControlsVersion",
            Cell: ({ row }) => (
              <Link
                Link
                style={{ color: "#212226" }}
                to={{ pathname: `${row.original.href_string}` }}
              >
                {row.original.bsmControlsVersion}
              </Link>
            ),
          },
          {
            Header: "OVR Controls Version",
            accessor: "ovmControlsVersion",
            Cell: ({ row }) => (
              <Link
                Link
                style={{ color: "#212226" }}
                to={{ pathname: `${row.original.href_string}` }}
              >
                {row.original.ovmControlsVersion}
              </Link>
            ),
          },
          {
            Header: "PFC Controls Version",
            accessor: "pfcControlsVersion",
            Cell: ({ row }) => (
              <Link
                Link
                style={{ color: "#212226" }}
                to={{ pathname: `${row.original.href_string}` }}
              >
                {row.original.pfcControlsVersion}
              </Link>
            ),
          },
          {
            Header: "FOD Controls Version",
            accessor: "fodControlsVersion",
            Cell: ({ row }) => (
              <Link
                Link
                style={{ color: "#212226" }}
                to={{ pathname: `${row.original.href_string}` }}
              >
                {row.original.fodControlsVersion}
              </Link>
            ),
          },
        )
      }
      return returnObj
    },
    [userProfile.isAdmin]
  );

  const handleEventClick = async (e) => {
    const uniqueIds = dateMap[e.event.start];
    let interrimData = [];
    for (const uid of uniqueIds) {
      interrimData.push(allData[uid]);
    }
    setModalData(interrimData);
    setShowModal(true);
    // set modal flag true 
    // shove tablecontainer inside modal 
    // remove columns 

  }


  return (
    <div className="overflow-scroll">
      <FullCalendar
        plugins={[multiMonthPlugin, interactionPlugin]}
        initialDate={newDate}
        initialView={"multiMonthFourMonth"}
        views={{
          multiMonthFourMonth: {
            type: 'multiMonth',
            duration: {
              months: 4,
            },

          }
        }}
        contentHeight={'auto'}
        eventClick={(e) => handleEventClick(e)}
        events={eventData}
      />
      <Modal
        show={showModal}
        size='xl'
        centered
        animation
        onHide={() => setShowModal(false)}
      >

        <Modal.Header closeButton style={{ backgroundColor: "#C7DE30" }}>
          <Modal.Title>{`Showing ${modalData.length} Charge Sessions`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-scroll border-2">
          <TableContainer columns={columns} data={modalData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
    </div>
  );
}
